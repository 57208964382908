import {Article} from "types/entities/Article";
import {PersonType} from "types/enums/PersonType";
import {ArticleType} from "types/enums/ArticleType";
import {ShopReducerState} from "store/shop/ShopReducer";
import {getArticleById, getArticlePrices} from "utils/utility";
import {ArticlesReducerState} from "store/articles/ArticlesReducer";
import {PAYMENT_TEST, GENERIC_ARTICLE_ID} from "helpers/config";

class CartHelper {
    articles: Article[] | undefined;
    setArticles = (articles: Article[]) => {
        this.articles = articles;
    };

    calculatePrice = (shop: ShopReducerState, articleState: ArticlesReducerState) => {
        if (!this.articles) {
            return null;
        }

        if (PAYMENT_TEST) {
            const pricesTest = shop.articleItems.map(item => {
                if (item.article_id !== GENERIC_ARTICLE_ID) {
                    return 0;
                }

                const article = getArticleById(item.article_id);
                return getArticlePrices(articleState, article.type, article.person_type, article.race_count_total).price / 100;
            });

            return pricesTest.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        }
        return shop.itemsCart.shopArticleItemsCart?.price;
    };

    calculatePriceForPersonType = (quantity: number, personType: PersonType, articleType: ArticleType) => {
        const article = this.findArticle(quantity, personType, articleType);
        if (!article) {
            return 0;
        }

        return article.article_value / 100;
    };

    findMatchingArticles = (personType: PersonType, articleType: ArticleType) => {
        if (PAYMENT_TEST) {
            return this.articles?.filter(article => article.id === GENERIC_ARTICLE_ID);
        }

        const matchingArticles = this.articles?.filter(article => {
            return article.id !== GENERIC_ARTICLE_ID && article.person_type === personType && article.ticket_type === "race" && article.type === articleType;
        });

        return matchingArticles;
    };

    findArticle = (quantity: number, personType: PersonType, articleType: ArticleType) => {
        if (quantity === 0) {
            return null;
        }
        // if (articleType === ArticleType.Action && quantity > 0) quantity = 3; //@todo Martin Foltyn -> urobit z vonku pri vybere do Shopping Cartu
        const matchingArticles = this.articles?.filter(article => {
            return (
                article.id !== GENERIC_ARTICLE_ID &&
                article.race_count_total === (quantity <= 15 ? quantity : 15) &&
                article.person_type === personType &&
                article.ticket_type === "race" &&
                article.type === articleType
            );
        });

        if (!matchingArticles?.length) {
            console.log(articleType, personType, quantity);
            console.error("no price detected");
            return null;
        }

        return matchingArticles[0];
    };
}

const shopInstance = new CartHelper();
export default shopInstance;
