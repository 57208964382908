import T from "components/T";
import React from "react";
import {closeModal, openModal} from "helpers/actions";
import {useDispatch} from "react-redux";
import NavBarItem from "./NavBarItem";
import SupportPopup from "./SupportPopup";
import NavBarItemNoLink from "./NavBarItemNoLink";

const NavBarLeft = () => {
    const dispatch = useDispatch();
    const openContactForm = () => {
        dispatch(openModal(<SupportPopup closeModal={() => dispatch(closeModal())} />, {}));
    };
    return (
        <div className="NavBarLeft hidden sm:flex w-16 2xl:w-20 fixed left-0 top-20 xl:top-24 2xl:top-28 right-auto bottom-0 bg-opacity-30 bg-gray-50 z-50 pt-0 pb-0 lg:pb-2 2xl:pb-8 flex-col justify-between">
            <ul className="flex flex-col mr-[1px] list-none pl-0 border-b border-gray-200" role="list">
                <NavBarItem img={"/img/icon-dashboard.svg"} text={<T>Account</T>} to={"/dashboard"} />
                <NavBarItem img={"/img/results.svg"} text={<T>Results</T>} to={"/results"} />
                <NavBarItem img={"/img/icon-shop.svg"} text={<T>Shop</T>} to={"/shop"} />
                <NavBarItem img={"/img/reservation-icon1.svg"} text={<T>Reservations</T>} to={"/reservations"} />
                <NavBarItem img={"/img/icon-racehistory.svg"} text={<T>Ranking</T>} to={"/ranking"} />
                <NavBarItem img={"/img/reservation-icon1.svg"} text={<T>Reservation History</T>} to={"/reservation-history"} />
                <NavBarItem img={"/img/icon-booking_1.svg"} text={<T>Purchase history</T>} to={"/purchase-history"} />
              <NavBarItem img={"/img/icon-referrals.svg"} text={<T>Referrals</T>} to={"/referrals"} />
              <NavBarItem img={"/img/icon-vouchers.svg"} text={<T>Vouchers</T>} to={"/vouchers"} />
              <NavBarItem img={"/img/icon-friends.svg"} text={<T>Friends</T>} to={"/friends"} />
              {/*<NavBarItem img={"/img/checkin.svg"} text={<T>Check in</T>} to={"/checkin"} />*/}
            </ul>
            <ul className="flex flex-col mr-[1px] list-none pl-0 border-b border-gray-200" role="list">
                <NavBarItemNoLink img={"/img/icon-support.svg"} text={<T>Support</T>} onClick={() => openContactForm()} />
                <NavBarItem img={"/img/icon-logout_1.svg"} text={<T>Log Out</T>} to={"/logout"} />
            </ul>
        </div>
    );
};
export default NavBarLeft;
