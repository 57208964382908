import { Driver } from "types/entities/Driver";
import { Profile } from "types/entities/Profile";

export interface  Notification {
  id: number;
  guid: string;
  notifiable_type: string;
  notifiable_id: number;
  type: string|NotificationType;
  data: NotificationData;
  read_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  driver?: Driver | Profile;
}

interface NotificationData {
  sender?: string;
  username?: string;
  message: string;
}
export enum NotificationType {
  UserNotification= "App\\Notifications\\UserNotification",
}