import React from "react";
import clsx from "clsx";

const NavBarItemContent = props => {
    const {img, text, textVisible} = props;
    return (
            <div
                className={clsx(
                    "NavBarItemContent w-full min-w-[1.5rem] p-2 2xl:p-4 h-full flex items-center relative border-t border-gray-200 sm:justify-center",
                    textVisible ? "justify-start" : "justify-center",
                )}
            >
                <div className={"w-7 h-6 sm:w-8 sm:h-8 shrink-0 flex justify-center items-center"}>
                    <img alt="" className={"w-7 h-6 2xl:h-7"} height="25" src={img} />
                </div>
                <div
                    className={clsx(
                        textVisible ? "flex" : "hidden absolute bg-white left-100 top-0 border-gray-200  border-t border-b border-r",
                        " sm:flex h-full transform-gpu overflow-hidden items-center rounded-tr-md rounded-br-md ",
                    )}
                >
                    <div
                        className="mr-8 ml-4 font-m text-black text-opacity-50 hover:text-opacity-100 text-xs sm:text-sm font-normal uppercase">{text}</div>
                </div>
            </div>
    );
};

export default NavBarItemContent;
