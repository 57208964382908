import React from "react";
import {useDispatch} from "useDispatch";
import T from "components/T";
import {MutedText} from "../../components/TextStyles/MutedText";
import {useTranslation} from "react-i18next";
import {currencyNumberFormat, getArticleById} from "utils/utility";
import ShopAction from "store/shop/ShopAction";
import {CheckCircleIcon, ExclamationTriangleIcon, TrashIcon, XCircleIcon} from "@heroicons/react/24/solid";
import {getDiscountedArticleItemPrice} from "utils/voucherUtils";
import {useTypedSelector} from "helpers/reducers";
import {ArticleType} from "types/enums/ArticleType";
import {ShopArticleItem} from "types/entities/ShopArticleItem";
import {ThunkAction} from "model/store/thunk";

interface ShopCartItemProps {
    shopArticleItem: ShopArticleItem;
}

const ShopCartItem = (props: ShopCartItemProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const activeArticle = getArticleById(props.shopArticleItem.article_id);
    const ticketsPrice = activeArticle?.type === ArticleType.Action ? activeArticle?.article_value * activeArticle?.race_count_total : activeArticle?.article_value;
    const discountedPrice = getDiscountedArticleItemPrice(props.shopArticleItem).price;

    const remove = () => {
        dispatch<ThunkAction<any,any>>(ShopAction.fetchDeleteShopArticleItem(props.shopArticleItem));
        dispatch(ShopAction.deleteShopArticleItem(props.shopArticleItem));
        dispatch(ShopAction.getShopArticleItemsCart());
    };

    return (
        <li className="ShopCartItem flex flex-row py-3 font-i">
            <div className="flex-grow flex-col">
                <div className="font-bold text-xl tracking-tight text-gray-800">
                    <div className="transaction-price flex flex-row items-start justify-between">
                        <h1 className="font-normal text-xl font-i">
                            <T>Ticket</T> - {props.shopArticleItem.article.type}
                        </h1>
                        {activeArticle.type === ArticleType.Action && (
                            <div>
                                <span className="mr-2 font-light">{props.shopArticleItem.quantity}x</span>
                            </div>
                        )}
                        <div className={"flex flex-row items-start"}>
                            {getDiscountedArticleItemPrice(props.shopArticleItem).present && (
                                <div className={"mr-1 h-7 flex items-center"}>
                                    {getDiscountedArticleItemPrice(props.shopArticleItem).applied ? (
                                        <CheckCircleIcon className={"w-4 h-4 mr-2 text-green-600"} />
                                    ) : (
                                        <XCircleIcon className={"w-4 h-4 mr-2 text-red-600"} />
                                    )}
                                </div>
                            )}

                            <aside className={"flex flex-col items-end relative select-none pointer-events-none"}>
                                <div>{currencyNumberFormat(discountedPrice)}</div>
                                <div>
                                    {getDiscountedArticleItemPrice(props.shopArticleItem).present && (
                                        <div className={"line-through text-gray-700 text-sm"}>{currencyNumberFormat(ticketsPrice)}</div>
                                    )}
                                </div>
                            </aside>
                        </div>
                    </div>
                    <div className={"flex flex-row justify-between items-end"}>
                        <div>
                            <MutedText className={"capitalize"} text={t(props.shopArticleItem.article.person_type)} />
                            <MutedText
                                text={activeArticle.race_count_total + "x " + (activeArticle.race_count_total === 1 ? t("race point") : t("race points"))}
                            />
                        </div>
                        <button
                            className="RemoveShoppingCartItem font-normal text-red-900 bg-red-200 px-1 rounded-xl flex flex-row items-center"
                            id={"remove-" + props.shopArticleItem.article_id}
                            type={"button"}
                            onClick={remove}
                        >
                            <TrashIcon className={"w-4 h-4 mr-2"} />
                            <T>Remove</T>
                        </button>
                    </div>
                    {activeArticle.type === ArticleType.Action && (
                        <div className="font-light font-i leading-4 text-xs sm:text-base tracking-tight text-gray-700 flex flex-row items-center justify-start mt-1">
                            <ExclamationTriangleIcon className={"w-4 h-4 mr-2 text-blue-800"} />
                            <span>{t("Ticket usable only on Monday and Tuesday")}</span>
                        </div>
                    )}
                </div>
            </div>
        </li>
    );
};

export default ShopCartItem;
