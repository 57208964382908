import React from "react";
import {OverlineText} from "../../components/TextStyles/OverlineText";
import {ShopItemsTypeList} from "./ShopItemsTypeList";
import {ShopCardSelector} from "./ShopCardSelector";
import {useTranslation} from "react-i18next";
import {isMobileOnly} from "react-device-detect";
import clsx from "clsx";

interface ShopItemsListSelectorProps {
    selectCard: (name: string) => void;
    active: string;
}

export const ShopItemsListSelector = (props: ShopItemsListSelectorProps) => {
    const {t} = useTranslation();
    return (
        <div
            className={clsx(
                "ShopItemsListSelector flex h-full overflow-x-auto sm:overflow-x-hidden w-full justify-between sm:justify-start items-start z-3 justify-self-start sm:space-y-4 2xl:space-y-6",
                isMobileOnly ? "flex-row lg:flex-col overflow-y-scroll lg:overflow-y-hidden" : "flex-row sm:flex-col overflow-y-scroll sm:overflow-y-hidden"
            )}
        >
            <ShopItemsTypeList title={<OverlineText text={t("Adults")} />}>
                <ShopCardSelector
                    active={props.active === "adult"}
                    color={"blue"}
                    name={"adult"}
                    subtype={t("Normal race")}
                    type={"Adult"}
                    onClick={props.selectCard}
                />
                <ShopCardSelector
                    active={props.active === "actionAdult"}
                    color={"black"}
                    name={"actionAdult"}
                    subtype={t("Action ticket")}
                    type={"3 for 2"}
                    onClick={props.selectCard}
                />
            </ShopItemsTypeList>
            <ShopItemsTypeList title={<OverlineText text={t("Children")} />}>
                <ShopCardSelector
                    active={props.active === "child"}
                    color={"green"}
                    name={"child"}
                    subtype={t("Normal race")}
                    type={"Children"}
                    onClick={props.selectCard}
                />
                <ShopCardSelector
                    active={props.active === "actionChild"}
                    color={"black"}
                    name={"actionChild"}
                    subtype={t("Action ticket")}
                    type={"3 for 2"}
                    onClick={props.selectCard}
                />
            </ShopItemsTypeList>
        </div>
    );
};
