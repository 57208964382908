import React from "react";

const Blur = props => {
    return (
        <div className="Blur w-full h-full py-1 object-fill text-current">
            <svg height={"100%"} width={"100%"} xmlns="http://www.w3.org/2000/svg" {...props}>
                <defs>
                    <filter colorInterpolationFilters="sRGB" id="a">
                        <feGaussianBlur result="blur" stdDeviation={30} />
                        <feComposite in="SourceGraphic" in2="blur" operator="atop" result="composite1" />
                        <feComposite in2="composite1" operator="in" result="composite2" />
                        <feComposite in2="composite2" operator="in" result="composite3" />
                    </filter>
                </defs>
                <rect
                    // transform="matrix(.79594 0 0 .32207 2.983 1.834)"
                    fill="currentColor"
                    fillRule="evenodd"
                    // strokeWidth={0.264583}
                    filter="url(#a)"
                    height={"70%"}
                    width={"100%"}
                />
            </svg>
        </div>
    );
};

export default Blur;
