import ShopAction from "store/shop/ShopAction";
import {ArticleItem} from "types/entities/ArticleItem";
import update from "immutability-helper";
import {ArticleType} from "types/enums/ArticleType";
import {ReducerResetAction} from "store/session/SessionReducer";
import SessionAction from "store/session/SessionAction";
import {ShopArticleItemsCart} from "types/entities/ShopArticleItemsCart";
import {ShopArticleItem} from "types/entities/ShopArticleItem";

export interface ShopReducerPushArticleItemAction {
    payload: ArticleItem;
    type: typeof ShopAction.PUSH_ARTICLE_ITEMS;
}

export interface ShopReducerSetArticleItemAction {
    payload: ArticleItem;
    type: typeof ShopAction.SET_ARTICLE_ITEM;
}

export interface ShopReducerSetArticleItemsAction {
    payload: ArticleItem[];
    type: typeof ShopAction.SET_ARTICLE_ITEMS;
}

export interface ShopReducerDeleteArticleItemAction {
    payload: ArticleItem;
    type: typeof ShopAction.DELETE_ARTICLE_ITEM;
}

export interface ShopReducerDeleteShopArticleItemAction {
    payload: ShopArticleItem;
    type: typeof ShopAction.DELETE_SHOP_ARTICLE_ITEM;
}

export interface ShopReducerSetShopArticleItemsCartAction {
    payload: ShopArticleItemsCart;
    type: typeof ShopAction.SET_SHOP_ARTICLE_ITEMS_CART;
}

export interface ShopReducerSetShopArticleItemsAction {
    payload: ShopArticleItem[];
    type: typeof ShopAction.SET_SHOP_ARTICLE_ITEMS;
}

export interface ShopReducerResetArticleItems {
    payload: any;
    type: typeof ShopAction.RESET_ARTICLE_ITEMS;
}

type ShopReducerAction =
    | ShopReducerPushArticleItemAction
    | ShopReducerSetArticleItemsAction
    | ShopReducerSetArticleItemAction
    | ShopReducerDeleteArticleItemAction
    | ShopReducerDeleteShopArticleItemAction
    | ShopReducerSetShopArticleItemsCartAction
    | ShopReducerSetShopArticleItemsAction
    | ShopReducerResetArticleItems
    | ReducerResetAction;

export interface ShopReducerState {
    articleItems: ArticleItem[];
    itemsCart: ShopArticleItemsCartState;
}

export interface ShopArticleItemsCartState {
    shopArticleItemsCart: ShopArticleItemsCart | null;
    shopArticleItems: ShopArticleItem[] | [];
}

class ShopReducer {
    initialState: ShopReducerState = {
        articleItems: [],
        itemsCart: {
            shopArticleItems: [],
            shopArticleItemsCart: null,
        },
    };

    reducer = (state: ShopReducerState = this.initialState, action: ShopReducerAction): ShopReducerState => {
        switch (action.type) {
            case ShopAction.SET_SHOP_ARTICLE_ITEMS_CART:
                return update(state, {
                    itemsCart: {
                        shopArticleItemsCart: {
                            $set: action.payload,
                        }
                    },
                });

            case ShopAction.SET_SHOP_ARTICLE_ITEMS: {
                return update(state, {
                    itemsCart: {
                        shopArticleItems:{
                            $set: action.payload
                        }
                    }
                })
            }
            case ShopAction.PUSH_ARTICLE_ITEMS:
                return update(state, {
                    articleItems: {
                        // @ts-ignore
                        $push: [action.payload],
                    },
                });

            case ShopAction.SET_ARTICLE_ITEM:
                // @ts-ignore
                const articleIndex = state.articleItems.findIndex(article => {
                    if (article.articleType === ArticleType.Action) {
                        // @ts-ignore
                        return article.personType === action.payload.personType && article.articleType === action.payload.articleType;
                    } else {
                        // @ts-ignore
                        return article.personType === action.payload.person_type && article.articleType === action.payload.type;
                    }
                });

                if (articleIndex === -1) {
                    return state;
                }

                // @ts-ignore
                if (action.payload.articleType === ArticleType.Action || action.payload.type === ArticleType.Action) {
                    return update(state, {
                        // @ts-ignore
                        articleItems: {
                            [articleIndex]: {
                                quantity: {
                                    // @ts-ignore
                                    $set: action.payload?.quantity,
                                },
                            },
                        },
                    });
                } else {
                    return update(state, {
                        articleItems: {
                            [articleIndex]: {
                                article_id: {
                                    // @ts-ignore
                                    $set: action.payload.article_id,
                                },
                            },
                        },
                    });
                }

            case ShopAction.SET_ARTICLE_ITEMS:
                return update(state, {
                    articleItems: {
                        // @ts-ignore
                        $set: action.payload,
                    },
                });

            case ShopAction.RESET_ARTICLE_ITEMS: {
                return this.initialState;
            }


            case ShopAction.DELETE_SHOP_ARTICLE_ITEM:
                // @ts-ignore
                const removeShopArticleIndex = state.itemsCart.shopArticleItems.findIndex(shopArticleItem => shopArticleItem.article_id === action.payload.article_id);

                if (removeShopArticleIndex === -1) {
                    return state;
                }

                return update(state, {
                    itemsCart: {
                        shopArticleItems: {
                            $splice: [[removeShopArticleIndex, 1]],
                        }
                    },
                });

            case ShopAction.DELETE_ARTICLE_ITEM:
                // @ts-ignore
                const removeArticleIndex = state.articleItems.findIndex(article => article.article_id === action.payload.article_id);

                if (removeArticleIndex === -1) {
                    return state;
                }

                return update(state, {
                    articleItems: {
                        $splice: [[removeArticleIndex, 1]],
                    },
                });
            case SessionAction.RESET:
                return this.initialState;

            default:
                return state;
        }
    };
}

export default ShopReducer;
