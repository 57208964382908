import React from "react";

const TrackImage = () => {
    return (
        <svg viewBox="0 0 97.39 96.44" xmlns="http://www.w3.org/2000/svg">
            <g data-name="Layer 2" id="Layer_2">
                <g data-name="Layer 1" id="Layer_1-2">
                    <path className="cls-1" d="M22.14,15.33A4.18,4.18,0,1,1,18,11.15a4.19,4.19,0,0,1,4.18,4.18Z" fill={"#434b9e"} />
                    <path
                        className="cls-2"
                        d="M17,37.25a1.4,1.4,0,0,0,1.92,0c.59-.55,14.37-13.71,14.37-21.92a15.33,15.33,0,0,0-30.66,0C2.63,23.54,16.41,36.7,17,37.25ZM11,15.33a7,7,0,1,1,7,7,7,7,0,0,1-7-7Z"
                        fill={"#f05841"}
                    />
                    <path
                        className="cls-1"
                        d="M97.38,74.17V32.23a11.43,11.43,0,1,0-22.85,0,4.11,4.11,0,0,1-8.21,0,11.43,11.43,0,1,0-22.85,0v28a4.11,4.11,0,0,1-8.21,0A17.64,17.64,0,1,0,0,60.17V77.24A19.21,19.21,0,0,0,19.19,96.43h55.9A22.32,22.32,0,0,0,97.38,74.17Zm-78.19,15A12,12,0,0,1,7.29,77.39v-.06l0-.2V60A10.32,10.32,0,0,1,28,60v.22a11.42,11.42,0,0,0,22.84,0v-28a4.11,4.11,0,0,1,8.21,0,11.43,11.43,0,1,0,22.85,0,4.11,4.11,0,0,1,8.21,0v42a15,15,0,0,1-15,15Z"
                        fill={"#434b9e"}
                    />
                </g>
            </g>
        </svg>
    );
};

export default TrackImage;
