import React from "react";
import clsx from "clsx";

const CardBackgroundStripes = (props) => {

    const stripeClassName = "relative h-1 mb-1 transform transform-gpu skew-x-0 skew-y-[30deg]";

    return (
        <>
            <div
                className={clsx(
                    "CardBackgroundStripes select-none pointer-events-none highlight-none transform-gpu overflow-hidden absolute inset-0 z-[1]",
                  props.rounded ? props.rounded : "rounded-3xl",
                    props.shadow ? props.shadow : "shadow-lg"
                )}
            >
                <div
                    className={clsx(
                        "CardBackgroundStripesList absolute z-30 left-auto right-0 inset-y-0 z-2 overflow-hidden w-[50%] ",
                      (props.activeStripes || typeof props.activeStripes === "undefined") ? "" : "grayscale-[80%]"
                    )}
                >
                    <div className={clsx(stripeClassName, "bg-[#f6956d]")}></div>
                    <div className={clsx(stripeClassName, "bg-[#434b9e]")}></div>
                    <div className={clsx(stripeClassName, "bg-[#f6956d]")}></div>
                </div>
                <div className="mbm-soft-light softlight opacity-30 absolute inset-0 rounded-br-3xl bg-gradient-to-tl from-black to-white"></div>
                <div className="absolute inset-0 z-1 bg-white bg-gradient-to-br from-transparent to-[#0000000d]"></div>
            </div>
            {/*<div className="CardBackgroundShadow absolute select-none pointer-events-none highlight-none inset-x-0 top-auto -mx-4 h-12 transform-gpu -bottom-5 group-hover:-bottom-8 overflow-visible text-dark-800 text-opacity-80">*/}
                {/*<Blur />*/}
            {/*</div>*/}
        </>
    );
};

export default CardBackgroundStripes;
