import {getStatsNumberTextByType} from "../utils/utility";
import React from "react";

const MyStatsItemLabel = props => {
    const {text, number, type} = props;
    return (
        <div className={"MyStatusItemLabel flex flex-col justify-between items-start h-full gap-2"}>
            <div className="font-k font-light leading-5 text-2xl lg:text-3xl h-4 lg:h-7">{getStatsNumberTextByType(type, number)}</div>
            <div className="flex-grow font-i align-top text-xs lg:text-base leading-3 lg:leading-3 font-normal opacity-50">
                <span>{text}</span>
            </div>
        </div>
    );
};

export default MyStatsItemLabel;
