import clsx from "clsx";
import {ServerImage} from "components/ServerImage";
import React from "react";
import { Driver } from "types/entities/Driver";
import {Profile} from "types/entities/Profile";
import { ReservationDriver } from "types/entities/ReservationDriver";
import {PersonType} from "types/enums/PersonType";
import { driverName } from "utils/utility";

interface GeneralRacerAvatarProps {
    driver?: ReservationDriver | Driver | Profile | undefined;
    className: string;
}

export const GeneralRacerAvatar = (props: GeneralRacerAvatarProps) => {
    const driverPicture = (driver: ReservationDriver | Driver | Profile) => {
        if (driver.profile_picture_url && !driver.profile_picture_url.includes("-helmet.svg")) {
            return driver.profile_picture_url ;
        }
        if (driver.person_type === PersonType.Adult) {
            return "/img/Blue-Racing-Helmet.png";
        }
        if (driver.person_type === PersonType.Child) {
            return "/img/Green-Racing-Helmet.png";
        }
        return "/img/White-Racing-Helmet.png";
    };
    return (
        <div
            className={clsx(props.className, "GeneralRacerAvatar z-2 bg-transparent flex justify-center items-center cursor-pointer")}
            //@ts-ignore
            title={props.driver && driverName(props.driver as Driver)}
        >
            <div
                className={
                    "ReservationPlayerAvatarHolder relative z-2 flex w-full h-full min-w-[2rem] min-h-[2rem] justify-center items-center rounded-full bg-white"
                }
            >
                <div
                    className={clsx(
                        "relative z-2 flex w-full h-full flex-col justify-center items-center rounded-full bg-opacity-30",
                        props.driver ? (props.driver.person_type === PersonType.Adult ? "bg-adultHelmet-500" : "bg-childHelmet-500") : "bg-dark-800"
                    )}
                >
                    <div
                        className={
                            "flex overflow-hidden object-contain flex-col w-[86%] h-[86%] shadow-softInset rounded-full bg-white bg-gradient-to-br from-white to-gray-600 items-center justify-center"
                        }
                    >
                        {props.driver ? (
                            <ServerImage alt={props.driver.name} src={driverPicture(props.driver)} />
                        ) : (
                            <ServerImage alt={"Driver has no name"} src={"/img/White-Racing-Helmet.png"} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
