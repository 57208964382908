import React from "react";
import MyStatsItemLabel from "./MyStatsItemLabel";

interface MyStatsItemProps {
    svg: string;
    number: number;
    text: React.ReactNode;
    isMobile: boolean;
    type?: string;
}

const MyStatsItem = (props: MyStatsItemProps) => {
    const {svg, number, text, type} = props;
    return (
        <div className={"MyStatsItem relative flex"}>
            <div className="flex flex-row z-3 p-1 justify-center items-center space-x-2">
                <div className="MyStatusItemIcon flex w-6 h-6 items-center justify-center rounded-full">
                    <img alt="" className={"w-full h-full object-contain object-center"} src={`/img/${svg}.svg`} />
                </div>
                <MyStatsItemLabel number={number} text={text} type={type} />
            </div>
        </div>
    );
};

export default MyStatsItem;
