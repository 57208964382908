import {createAction} from "helpers/createAction";
import {ArticleItem} from "types/entities/ArticleItem";
import ajax from "helpers/ajax";
import {DEFAULT_HALL_ID} from "helpers/config";
import {ShopArticleItemsCart} from "types/entities/ShopArticleItemsCart";
import {
    ShopReducerDeleteArticleItemAction,
    ShopReducerDeleteShopArticleItemAction,
    ShopReducerPushArticleItemAction,
    ShopReducerResetArticleItems,
    ShopReducerSetArticleItemAction,
    ShopReducerSetArticleItemsAction,
    ShopReducerSetShopArticleItemsAction,
    ShopReducerSetShopArticleItemsCartAction,
} from "store/shop/ShopReducer";
import {ThunkAction} from "model/store/thunk";
import {ShopArticleItem} from "types/entities/ShopArticleItem";

class ShopAction {
    static RESET_ARTICLE_ITEMS = "ShopAction.ResetArticleItems";
    static PUSH_ARTICLE_ITEMS = "ShopAction.PushArticleItems";
    static SET_SHOP_ARTICLE_ITEMS_CART = "ShopAction.SetShopArticleItemsCart";
    static SET_SHOP_ARTICLE_ITEMS = "ShopAction.SetShopArticleItems";
    static SET_ARTICLE_ITEM = "ShopAction.SetArticleItem";
    static SET_ARTICLE_ITEMS = "ShopAction.SetArticleItems";
    static DELETE_ARTICLE_ITEM = "ShopAction.DeleteArticleItem";
    static DELETE_SHOP_ARTICLE_ITEM = "ShopAction.DeleteShopArticleItem";

    static initCart = () => {
        return dispatch => {
            return ajax.post("booking/cart/create", {hall_id: DEFAULT_HALL_ID});
        };
    };

    static addShopArticleItem = (articleItem: ArticleItem, shopArticleItemsCart: ShopArticleItemsCart) => {
        return (dispatch) => {
            dispatch(ShopAction.fetchAddArticleItem(articleItem, shopArticleItemsCart)).then(r => {
                dispatch(ShopAction.setShopArticleItems(r.shopArticleItems))
                dispatch(ShopAction.setShopArticleItemsCart(r.shopArticleItemsCart))
            });
        }
    }

    static getShopArticleItemsCart = () => {
        return (dispatch) => {
            return dispatch(ShopAction.fetchGetShopArticleItemsCart()).then(r => {
                dispatch(ShopAction.setShopArticleItemsCart(r.shopArticleItemsCart));
                dispatch(ShopAction.setShopArticleItems(r.shopArticleItems))

            });
        }
    }

    static createShopArticleItemsCart = (profile) => {
        return (dispatch) => {
            return dispatch(ShopAction.fetchCreateShopArticleItemsCart(profile ? profile.home_hall_id : DEFAULT_HALL_ID)).then(r => {
                dispatch(ShopAction.setShopArticleItemsCart(r.shopArticleItemsCart))
                dispatch(ShopAction.setShopArticleItems(r.shopArticleItems))

            });
        }
    };

    static fetchCreateNewDriverForItemsCart = (data): ThunkAction<Promise<ShopArticleItemsCart>> => {
        return (dispatch, getState) => {
            const session = getState().sessionState.session;
            return ajax
              .post("/shop/create-driver-for-items-cart", data, {
                  withCredentials: true,
                  headers: {
                      Authorization: `Bearer ${session.access_token}`,
                  },
              })
              .then(r => {
                  return r;
              })
              .catch(err => {
                  return err;
              });
        };
    };

    static fetchGetShopArticleItemsCart = (): ThunkAction<Promise<ShopArticleItemsCart>> => {
        return (dispatch, getState) => {
            const session = getState().sessionState.session;
            return ajax
                .get("/shop/list-shop-article-items-cart", {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                })
                .then(r => {
                    return r;
                })
                .catch(err => {
                    return err;
                });
        };
    };

    static fetchCreateShopArticleItemsCart = (hall_id?: number): ThunkAction<Promise<ShopArticleItemsCart>> => {
        return (dispatch, getState) => {
            const session = getState().sessionState.session;
            return ajax
                .get("/shop/create-shop-article-items-cart" + (hall_id ? "?hall_id="+hall_id : ""), {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                })
                .then(r => {
                    return r;
                })
                .catch(err => {
                    return err;
                });
        };
    };

    static fetchAddArticleItem = (articleItem: ArticleItem, shopArticleItemsCart: ShopArticleItemsCart) => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;
            return ajax.post(
                "/shop/add-article-item",
                {
                    article_id: articleItem.article_id,
                    quantity: articleItem.quantity,
                    shop_article_items_cart_id: shopArticleItemsCart.id,
                },
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                }
            );
        };
    };

    static fetchDeleteShopArticleItem = (shopArticleItem: ShopArticleItem): ThunkAction<Promise<ShopArticleItemsCart>> => {
        return ((dispatch, getState) => {
            const session = getState().sessionState.session;
            return ajax
              .delete("/shop/" + shopArticleItem.shop_article_items_cart_id + "/delete-article-item/"+shopArticleItem.guid, {
                  withCredentials: true,
                  headers: {
                      Authorization: `Bearer ${session.access_token}`,
                  },
              })
              .then(r => {
                  return r;
              })
              .catch(err => {
                  return err;
              });
        })
    }

    static resetShoppingCart = (): ShopReducerResetArticleItems => {
        return createAction(ShopAction.RESET_ARTICLE_ITEMS, null);
    };

    static pushArticleItem = (articleItem: ArticleItem): ShopReducerPushArticleItemAction => {
        return createAction(ShopAction.PUSH_ARTICLE_ITEMS, articleItem);
    };

    static setShopArticleItemsCart = (shopArticleItemsCart: ShopArticleItemsCart): ShopReducerSetShopArticleItemsCartAction => {
        return createAction(ShopAction.SET_SHOP_ARTICLE_ITEMS_CART, shopArticleItemsCart);
    };

    static setShopArticleItems = (shopArticleItems: ShopArticleItem[]): ShopReducerSetShopArticleItemsAction => {
        return createAction(ShopAction.SET_SHOP_ARTICLE_ITEMS, shopArticleItems);
    };

    static setArticleItems = (articleItems: ArticleItem[]): ShopReducerSetArticleItemsAction => {
        return createAction(ShopAction.SET_ARTICLE_ITEMS, articleItems);
    };

    static setArticleItem = (articleItem: ArticleItem): ShopReducerSetArticleItemAction => {
        return createAction(ShopAction.SET_ARTICLE_ITEM, articleItem);
    };

    static deleteArticleItem = (articleItem: ArticleItem): ShopReducerDeleteArticleItemAction => {
        return createAction(ShopAction.DELETE_ARTICLE_ITEM, articleItem);
    };

    static deleteShopArticleItem = (shopArticleItem: ShopArticleItem): ShopReducerDeleteShopArticleItemAction => {
        return createAction(ShopAction.DELETE_SHOP_ARTICLE_ITEM, shopArticleItem);
    };
}

export default ShopAction;
