import React from "react";
import clsx from "clsx";
import Blur from "./Blur";

const CardBackgroundShadowRight = props => {
    const {rounded} = props;
    let {shadow, background} = props;
    if (shadow == null) shadow = "shadow-lg";
    if (background == null) background = "bg-dark-800";

    return (
        <>
            <div className={clsx("CardBackgroundShadowRight absolute overflow-hidden ring-offset-4 inset-0 z-[1]", rounded, shadow)}>
                <div className="mbm-soft-light softlight opacity-30 absolute inset-0 rounded-br-3xl bg-gradient-to-tl from-black to-transparent"></div>
                <div className="absolute inset-0 z-1 bg-gradient-to-br from-[#000000f0] to-[#00000008]"></div>
            </div>

            <div className="CardBackgroundShadow absolute inset-x-0 top-auto -mx-6 h-12 transform-gpu -bottom-7 group-hover:-bottom-8 overflow-visible text-dark-800 text-opacity-80">
                <Blur />
            </div>
        </>
    );
};

export default CardBackgroundShadowRight;
