import clsx from "clsx";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export interface SwitchItem {
    name: string;
    value: string;
    disabled?: boolean;
}

interface AreaSwitchProps {
    initialActive: any;
    items: SwitchItem[];
    onClick: (switchItem: SwitchItem) => void;
}

const AreaSwitch = (props: AreaSwitchProps) => {
    const [active, setActive] = useState(props.initialActive);

    const {t} = useTranslation();
    const handleSwitch = () => {
        const inactiveItem = props.items.find(item => item.value !== active);
        if (typeof inactiveItem !== "undefined") {
            if (!inactiveItem.disabled) {
                setActive(inactiveItem.value);
            }
            props.onClick(inactiveItem);
        }
    };
    return (
        <div
            className="AreaSwitch flex relative select-none w-auto justify-around items-center p-1 rounded-full h-auto bg-whitewash shadow-white cursor-pointer transition-all transform-gpu "
            onClick={handleSwitch}
        >
            <div
                className={clsx(
                    "absolute flex w-1/2 h-full justify-center items-center rounded-full shadow-dark z-1 bg-orange-800 transform-gpu ease-in-out duration-300 transition-all ",
                    active === props.items[1].value && "translate-x-1/2",
                    active === props.items[0].value && "-translate-x-1/2"
                )}
                draggable={true}
                onDragStart={handleSwitch}
            />
            {props.items.map((item, index) => {
                return (
                    <div
                        key={index}
                        className={clsx(
                            "pointer-events-none flex z-2 flex-row justify-center items-center flex-grow w-1/2 no-underline hover:no-underline px-1 pr-2 outline-none focus:outline-none data-" +
                                (index + 1) +
                                " w-inline-block",
                            item.disabled && "text-opacity-30 cursor-not-allowed",
                            active === item.value ? "text-white" : "text-gray-800"
                        )}
                    >
                        <div className="font-m font-bold uppercase no-underline transform-gpu transform skew-x-[-10deg] px-2 tracking-tight">
                            {t(item.name)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default AreaSwitch;
