import React from "react";
import {MutedText} from "../../components/TextStyles/MutedText";
import {useTranslation} from "react-i18next";
import {currencyNumberFormat} from "utils/utility";
import clsx from "clsx";

interface ShopCartSummaryItemProps {
    amount: number;
    label: string;
}
const ShopCartSummaryItem = (props: ShopCartSummaryItemProps) => {
    return (
        <div className="ShopCartSummaryItem flex flex-col justify-center items-center flex-grow select-none">
            <div className={clsx(props.label === "Total" && "border border-gray-600 rounded-xl", "w-full flex flex-col justify-center items-center p-1")}>
                <div className={"font-m text-sm sm:text-base lg:w-[120px] flex-grow text-center"}>{currencyNumberFormat(props.amount)}</div>
                <MutedText text={props.label} />
            </div>
        </div>
    );
};

export const ShopCartSummary = ({prices, active}) => {
    const {t} = useTranslation();
    return (
        <div className="ShopCartSummary relative flex flex-row w-full justify-between divide-x divide-gray-500 mb-2 sm:mb-4 select-none">
            <ShopCartSummaryItem amount={prices.pricePerRace} label={t("Per race")} />
            <ShopCartSummaryItem amount={prices.price} label={t("Total per ticket")} />
            <ShopCartSummaryItem amount={prices.savings} label={t("Saves")} />
        </div>
    );
};
