import {Payment} from "types/entities/Payment";
import {
    PurchaseHistoryReducerPushPurchaseHistoryAction,
    PurchaseHistoryReducerSetPurchaseHistoryAction
} from "store/purchaseHistory/PurchaseHistoryReducer";
import {createAction} from "helpers/createAction";
import ajax from "helpers/ajax";

class PurchaseHistoryAction {

    static readonly PUSH_PURCHASE_HISTORY = "PurchaseHistoryAction.PushPurchaseHistory";
    static readonly SET_PURCHASE_HISTORY = "PurchaseHistoryAction.SetPurchaseHistory";
    static readonly CLEAR_PURCHASE_HISTORY = "PurchaseHistoryAction.ClearPurchaseHistory";

    static pushPurchaseHistory = (purchaseHistory: Payment[]): PurchaseHistoryReducerPushPurchaseHistoryAction => {
        return createAction(PurchaseHistoryAction.PUSH_PURCHASE_HISTORY, purchaseHistory);
    }

    static setPurchaseHistory = (purchaseHistory: Payment[]): PurchaseHistoryReducerSetPurchaseHistoryAction => {
        return createAction(PurchaseHistoryAction.SET_PURCHASE_HISTORY, purchaseHistory);
    };

    static clearPurchaseHistory = (payload: []) => {
        return createAction(PurchaseHistoryAction.CLEAR_PURCHASE_HISTORY, payload);
    };
    static fetchPurchaseHistory = (yearMonthFilter?: string): (dispatch, getState) => Promise<Payment[]> => {
        return async (dispatch, getState): Promise<Payment[]> => {
            const session = getState().sessionState.session;

            return ajax
                .get("/user/purchase-history", {
                    params: {
                        year_month: yearMonthFilter,
                    },
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                })
                .then(r => {
                    dispatch(PurchaseHistoryAction.setPurchaseHistory(r.purchaseHistory));
                    return r;
                })
                .catch(err => {
                    return err;
                });
        };
    }
}

export default PurchaseHistoryAction;