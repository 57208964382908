import React from "react";

interface CardContainerProps {
    children: React.ReactNode | React.ReactNode[];
}

const ShopCardContainer = (props: CardContainerProps) => {
    return (
        <div
            className="CardContainer relative flex w-full h-full p-4 flex-col z-3">
            <div className="CardContainerInner flex flex-col grow">
                <div className="flex flex-col items-center justify-between w-full grow">{props.children}</div>
            </div>
        </div>
    );
};

export default ShopCardContainer;
