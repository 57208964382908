import T from "components/T";
import React, {Fragment, useState} from "react";
import {closeModal, openModal} from "helpers/actions";
import {useDispatch} from "react-redux";
import NavBarItem from "./NavBarItem";
import SupportPopup from "./SupportPopup";
import {Bars3Icon} from "@heroicons/react/24/solid";
import {Dialog, Transition} from "@headlessui/react";
import HallLogo from "pages/Front/components/HallLogo";
import {useTypedSelector} from "helpers/reducers";
import NavBarItemNoLink from "pages/Front/components/NavBarItemNoLink";
import clsx from "clsx";

const NavBarBottom = () => {
    const dispatch = useDispatch();
    const openContactForm = () => {
        dispatch(openModal(<SupportPopup closeModal={() => dispatch(closeModal())} />, {}));
    };

    const hall = useTypedSelector(state => state.hallState.hall);

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const setSideBarOpenHandler = (timeout: number) => {
        const timer = setTimeout(() => {
            setSidebarOpen(!sidebarOpen);
        }, timeout);
        return () => {
            clearTimeout(timer);
        };
    };

    return (
        <div className="NavBarBottom flex sm:hidden w-auto fixed left-0 top-auto bottom-0 right-0 z-50 h-[4rem] flex-col justify-between">
            <ul className="flex grow list-none h-full z-20 gap-x-4 bg-white mx-4" role="list">
                <NavBarItem img={"/img/icon-dashboard.svg"} text={<T>Account</T>} to={"/dashboard"} />
                <NavBarItem img={"/img/results.svg"} text={<T>Results</T>} to={"/results"} />
                <NavBarItem img={"/img/icon-shop.svg"} text={<T>Shop</T>} to={"/shop"} />
                <NavBarItem img={"/img/reservation-icon1.svg"} text={<T>Reservations</T>} to={"/reservations"} />
                <li className="MainMenu w-full h-auto list-type-none bg-white flex flex-row justify-center items-center">
                    <div className={" p-2 lg:py-3 2xl:p-4 flex items-center justify-center"}>
                        <button className={"h-8 w-8 flex items-center flex-col justify-center"} onClick={() => setSidebarOpen(!sidebarOpen)}>
                            <Bars3Icon className={"w-7 h-7"} />
                        </button>
                    </div>
                </li>
            </ul>
            <Transition.Root as={Fragment} show={sidebarOpen} unmount={false}>
                <Dialog as="div" className={clsx("relative z-40 md:hidden")} onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300 transform-gpu"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300 transform-gpu"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        unmount={false}
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <div className={clsx(!sidebarOpen && "select-none pointer-events-none", "fixed inset-0 flex z-40")}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform-gpu"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform-gpu"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                            unmount={false}
                        >
                            <Dialog.Panel className="relative flex-1 flex flex-col max-w-2xs w-full pt-2 pb-2 bg-white border-r border-gray-600 shadow-lg">
                                <div className="flex-shrink-0 flex items-center px-4">{hall && <HallLogo hall={hall} />}</div>
                                <div className="mt-0 flex-1 h-0 overflow-y-auto ">
                                    <ul
                                        className="px-2 list-none items-start justify-start flex flex-col h-full"
                                        onClick={() => setSideBarOpenHandler(300)}
                                    >
                                        <NavBarItem img={"/img/icon-dashboard.svg"} text={<T>Account</T>} textVisible={true} to={"/dashboard"} />
                                        <NavBarItem img={"/img/icon-settings_1.svg"} text={<T>Profile</T>} textVisible={true} to={"/profile"} />
                                        <NavBarItem img={"/img/icon-shop.svg"} text={<T>Shop</T>} textVisible={true} to={"/shop"} />
                                        <NavBarItem img={"/img/icon-racehistory.svg"} text={<T>Ranking</T>} textVisible={true} to={"/ranking"} />
                                        <NavBarItem img={"/img/results.svg"} text={<T>Results</T>} textVisible={true} to={"/results"} />
                                        <NavBarItem img={"/img/icon-referrals.svg"} text={<T>Referrals</T>} textVisible={true} to={"/referrals"} />
                                        <NavBarItem img={"/img/icon-friends.svg"} text={<T>Friends</T>} textVisible={true} to={"/friends"} />
                                        <NavBarItem img={"/img/icon-vouchers.svg"} text={<T>Vouchers</T>} textVisible={true} to={"/vouchers"} />
                                        <NavBarItem img={"/img/reservation-icon1.svg"} text={<T>Reservations</T>} textVisible={true} to={"/reservations"} />
                                        <NavBarItem
                                            img={"/img/reservation-icon1.svg"}
                                            text={<T>Reservation History</T>}
                                            textVisible={true}
                                            to={"/reservation-history"}
                                        />
                                        <NavBarItem
                                            img={"/img/icon-booking_1.svg"}
                                            text={<T>Purchase history</T>}
                                            textVisible={true}
                                            to={"/purchase-history"}
                                        />
                                        <li className={"grow"} />
                                        <NavBarItemNoLink
                                            img={"/img/icon-support.svg"}
                                            text={<T>Support</T>}
                                            textVisible={true}
                                            onClick={() => openContactForm()}
                                        />
                                        <NavBarItem img={"/img/icon-logout_1.svg"} text={<T>Log Out</T>} textVisible={true} to={"/logout"} />
                                    </ul>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
};
export default NavBarBottom;
