import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {ChevronDoubleLeftIcon, ChevronDoubleRightIcon} from "@heroicons/react/24/solid";

interface SliderProps {
    children: any[];
    delay: number;
}

const Slider = (props: SliderProps) => {
    const [timeout, setTimeoutState] = useState(null);
    const [active, setActive] = useState(0);

    const setNext = (way: number) => {
        setActive(() => {
            if (props.children.length === active + way) {
                return 0;
            }
            if (active + way < 0) {
                return 0;
            }
            return active + way;
        });
        setTimeoutState(null);
    };
    useEffect(() => {
        const theTimeout = setInterval(() => {
            setActive(act => {
                if (props.children.length === act + 1) {
                    return 0;
                }
                return act + 1;
            });
        }, props.delay || 4000);
        // @ts-ignore
        setTimeoutState(theTimeout);

        return () => {
            // if (timeout) {
                // @ts-ignore
                clearInterval(theTimeout);
            // }
        };
    }, []);
    return (
        <div
            className="h-auto transform-gpu relative text-center clear-both z-10 rounded-3xl cursor-pointer"
            data-animation="outin"
            data-autoplay="1"
            data-delay="4000"
            data-duration="1200"
            data-easing="ease-in-out"
            data-infinite="1"
        >
            <div className="relative overflow-hidden inset-x-0 whitespace-nowrap px-4 z-2 h-full">
                {props.children.map((child, index) => {
                    return React.cloneElement(child, {
                        ...child.props,
                        key: index,
                        active: active === index,
                    });
                })}
            </div>
            <button
                className="SliderLeft absolute z-3 flex items-center justify-start w-16 inset-y-0 left-0 m-auto cursor-pointer overflow-hidden text-white text-3xl select-none"
                role="button"
                onClick={() => setNext(-1)}
            >
                <ChevronDoubleLeftIcon className={"h-4 w-4 sm:w-6 sm:h-6 ml-1 sm:ml-2"} />
            </button>
            <button
                className="SliderRight absolute z-3 flex items-center justify-end d w-16 inset-y-0 right-0 m-auto cursor-pointer overflow-hidden text-white text-3xl select-none"
                role="button"
                onClick={() => setNext(+1)}
            >
                <ChevronDoubleRightIcon className={"h-4 w-4 sm:w-6 sm:h-6 mr-1 sm:mr-2"} />
            </button>
            <div className="SliderBubbles left-auto top-0 right-0 bottom-auto h-5 pr-4 text-xs z-2 absolute m-auto text-center pt-2">
                {props.children.map((child, index) => {
                    return (
                        <button
                            key={index}
                            aria-selected="true"
                            className={clsx("rounded-full w-3 h-3 cursor-pointer bg-white inline-block mr-1", active === index ? "opacity-80" : "opacity-20")}
                            role="button"
                        ></button>
                    );
                })}
            </div>
        </div>
    );
};
export default Slider;
