import React from "react";
import {forEach} from "helpers/helpers";
import {ForTemplate} from "types/entities/RaceResultData";
import {useTypedSelector} from "helpers/reducers";
import clsx from "clsx";

interface RaceResultProps {
    res: ForTemplate;
}

const RaceResultLaps = (props: RaceResultProps) => {
    const session = useTypedSelector(state => state.sessionState.session);
    const loggedUserId = session?.uid;
    const detailStatsLength = props.res.detailStats.length;

    if (!props.res.result.raceResultDrivers) {
        return <div></div>;
    }
    return (
        <div className="RaceResultLaps relative h-full">
            <div className="relative flex items-center">
                <div className="overflow-x-auto w-full pb-6">
                    {forEach(props.res.laps, (lapDetails, lapNumber) => {
                        return (
                            <div
                                key={lapNumber}
                                className={`RaceResultLap relative z2 pt-2 pb-2 pl-4 pr-4 text-center bg-white justify-start items-start mt-0 grid grid-results-${
                                    detailStatsLength + 1
                                } border-b border-gray-300`}
                            >
                                <div className="relative z-3 p-2 text-left w-auto">
                                    <div className="font-sans-serif-g text-xl leading-4 font-light">{lapNumber}</div>
                                </div>
                                {forEach(lapDetails, lap => {
                                    if (!props.res.result.raceResultDrivers) {
                                        return <></>;
                                    }
                                    const driverId = lap.driver_id;
                                    const isDriver = props.res.result.raceResultDrivers.filter(driver => driver.driver_id === driverId) || false;
                                    const isBestLap = isDriver[0].best_lap_time === lap.laptime;
                                    const isLoggedUser = driverId === loggedUserId;
                                    return (
                                        <div
                                            key={driverId}
                                            className={clsx(
                                                "relative flex w-20 z-3 p-2 text-left",
                                                isBestLap && !isLoggedUser && "text-red-f0",
                                                isBestLap && isLoggedUser && "text-white bg-red-500 rounded-2xl"
                                            )}
                                        >
                                            <div className="font-sans-serif-g text-xl leading-4 font-light">
                                                {lap.laptime === false ? "-" : (lap.laptime / 100).toFixed(2) + "s"}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default RaceResultLaps;
