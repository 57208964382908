import React from "react";
import {Link} from "react-router-dom";
import ImageBubble from "./ImageBubble";
import {useDispatch} from "../../../useDispatch";
import {closeModal, openModal} from "../../../helpers/actions";
import SupportPopup from "./SupportPopup";

const CardProfileToolbar = () => {
    const dispatch = useDispatch();
    const openContactForm = () => {
        dispatch(openModal(<SupportPopup closeModal={() => dispatch(closeModal())} />, {}));
    };
    return (
        <div className="absolute left-0 top-0 right-auto bottom-0 pt-12 pl-4">
            <div className="flex flex-col items-center text-center justify-center .u-flex-vertical-center">
                <Link
                    className="relative z-1 flex overflow-visible w-8 h-8 items-center justify-center rounded-full bg-transparent shadow-lg cursor-pointer text-center text-gray-300 text-sm font-medium uppercase"
                    to={"/profile"}
                >
                    <ImageBubble>
                        <svg
                            className="w-full h-full Profile"
                            fill="none"
                            height="18"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            viewBox="0 0 24 24"
                            width="18"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13.309 3l.186 1a2.0003 2.0003 0 002.1786 1.6334A1.9996 1.9996 0 0016.6 5.29l.842-.58 1.848 1.852-.58.842A2.0002 2.0002 0 0020 10.505l1 .186v2.618l-1 .191a1.9998 1.9998 0 00-1.285 3.1l.58.842-1.857 1.848-.842-.58A2 2 0 0013.5 20l-.186 1h-2.623l-.186-1a2.0005 2.0005 0 00-1.2008-1.4915A2.0005 2.0005 0 007.4 18.71l-.842.58-1.848-1.852.58-.842a1.9999 1.9999 0 00-1.285-3.1l-1-.186v-2.619l1-.186a1.9992 1.9992 0 001.488-1.2026A2 2 0 005.29 7.4l-.58-.842L6.562 4.71l.842.58a2.0001 2.0001 0 003.1-1.285l.186-1L13.309 3z"
                                strokeMiterlimit="10"
                            ></path>
                            <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                    </ImageBubble>
                </Link>
                <button
                    className="relative z-1 flex overflow-visible w-8 h-8 items-center justify-center rounded-full bg-transparent shadow-lg cursor-pointer text-center text-gray-300 text-sm font-medium uppercase mt-4"
                    onClick={openContactForm}
                >
                    <ImageBubble>
                        <svg
                            className="w-full h-full"
                            fill="none"
                            height="18"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            viewBox="0 0 24 24"
                            width="18"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3 12a9 9 0 119 9 8.4458 8.4458 0 01-4-1l-5 1 1-5a9.008 9.008 0 01-1-4v0zM8.01 10h8M8.01 14h6"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                            ></path>
                        </svg>
                    </ImageBubble>
                </button>
            </div>
        </div>
    );
};

export default CardProfileToolbar;
