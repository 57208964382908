import React, {useState} from "react";
import T from "components/T";
import {Link} from "react-router-dom";
import CardBackgroundStripes from "pages/Front/components/CardBackgroundStripes";
import {closeModal, openModal} from "helpers/actions";
import {useDispatch} from "useDispatch";
import ShopCardContainer from "pages/Front/components/Cards/ShopCardContainer";
import CardBackground from "pages/Front/components/CardBackground";

interface Props {
    setHintActive: (value: boolean) => void;
    title: string;
    hint: string;
}

const DashboardWidgetRidesHintModal = (props: Props) => {
    return (
        <div className={"w-full max-w-2xl relative z-50"}>
            <div className="relative flex overflow-hidden w-full h-full p-6 flex-row justify-center items-start z-50">
                <div className="close-btn pointer-events-auto">
                    <a
                        className="relative z-1 overflow-visible flex w-8 h-8 m-0 justify-center items-center rounded-full bg-transparent shadow-lg mr-2
                                    text-sm uppercase cursor-pointer spacing-px no-underline"
                        href="#"
                        onClick={() => props.setHintActive(false)}
                    >
                        <div className="w-full h-full p-2">
                            <img alt="" src="/img/close.svg" />
                        </div>
                    </a>
                </div>
                <div className="u-flex-vertical-center">
                    <h1 className="t-h2">{props.title}</h1>
                    <div className="u-marg-t-sm u-align-center">
                        <p className="t-p3">{props.hint}</p>
                    </div>
                </div>
            </div>
            <CardBackground rounded={"rounded-3xl"} shadow={"shadow-lg"} />
        </div>
    );
};

const DashboardWidgetRides = props => {
    const [hintActive, setHintActiveState] = useState(false);
    const dispatch = useDispatch();

    const setHintActive = newState => {
        //const newState = !hintActive;
        setHintActiveState(newState);
        if (newState === false) {
            dispatch(closeModal());
        } else {
            dispatch(openModal(<DashboardWidgetRidesHintModal hint={props.hint} setHintActive={setHintActive} title={props.title} />, {}));
        }
    };

    return (
        <div className="DashboardWidgetRides mb-0 relative flex h-auto min-w-[160px] w-auto items-center rounded-3xl flex-col">
            <ShopCardContainer>
                {/*<div className="flex flex-col justify-end h-32 lg:h-40 xl:h-44">*/}
                <div className="flex flex-col justify-end h-32 md:h-auto">
                    <span className="my-0 font-k text-9xl lg:text-11xl 2xl:text-13xl font-medium text-dark-900 leading-[1.1]">{props.count}</span>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <div className="font-k text-3xl lg:text-4xl 2xl:text-5xl font-normal upper">{props.title}</div>
                    <div className="font-i text-sm opacity-50">
                        <T>Rides</T>
                    </div>
                </div>
                <div className="w-full mt-4 2xl:mt-8">
                    <Link
                        className="flex relative z-1 w-full h-10 px-2 py-0 bg-dark-800 rounded-2xl text-white  uppercase justify-center items-center shadow-lg shadow-dark-800/20"
                        to={"/shop/"+props.type}
                    >
                        <div className={"font-bold"}>{props.count > 0 ? <T>Buy more</T> : <T>Buy</T>}</div>
                    </Link>
                </div>
                {props.hint && (
                    <div className="absolute left-4 top-4 right-auto bottom-auto w-5 cursor-pointer" onClick={() => setHintActive(true)}>
                        <img alt="" src="/img/info.svg" />
                    </div>
                )}
            </ShopCardContainer>
            <CardBackgroundStripes rounded={"rounded-3xl"} shadow={"shadow-lg"} />
        </div>
    );
};
export default DashboardWidgetRides;
