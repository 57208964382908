import axios from "axios";
import AxiosCommon from "helpers/axios/AxiosCommon";
import store from "helpers/store";
import {setError} from "helpers/actions";
import localforage from "localforage";
import {setupCache} from "axios-cache-adapter";
import {ROOT_API} from "helpers/config";

export const forageStore = localforage.createInstance({
    // List of drivers used
    driver: [
        //memoryDriver,
        //localforage.INDEXEDDB,
        localforage.LOCALSTORAGE,
        //memoryDriver._driver
    ],
    // Prefix all storage keys to prevent conflicts
    name: "axiosCache",
});

export function createAxiosInstance() {
    const cache = setupCache({
        maxAge: window.location.href.indexOf("localhost") !== -1 ? -1 : -1, //1000 * 1 : 20 * 1000, // ms, disabled for develop
        store: forageStore,
    });

    const ajax = axios.create({
        baseURL: ROOT_API,
        adapter: cache.adapter,
        withCredentials: true,
    });

    //instance.get(ROOT_API.replace("/api/", "") + "/sanctum/csrf-cookie");
    ajax.defaults.withCredentials = true;
    //ajax.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    ajax.defaults.headers.common["Content-Type"] = "application/json";
    return ajax;
}

function createDefaultInstance() {
    const ajax = createAxiosInstance();
    ajax.defaults.withCredentials = true;
    //ajax.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    ajax.defaults.headers.common["Content-Type"] = "application/json";
    AxiosCommon.applyRequestInterceptor(ajax, setError, store);
    AxiosCommon.applyResponseInterceptor(ajax, setError, store);
    return ajax;
}

const defaultInstance = createDefaultInstance();

export default defaultInstance;
