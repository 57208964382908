import React from "react";
import {useTranslation} from "react-i18next";
import {ShopItemText} from "../../components/TextStyles/ShopItemText";
import {MutedText} from "../../components/TextStyles/MutedText";
import clsx from "clsx";
import CardBackground from "../../components/CardBackground";
import {isMobileOnly} from "react-device-detect";

interface ShopCardSelectorProps {
    type: string;
    name: string;
    subtype: string;
    color: string;
    onClick: (name: string) => void;
    active: boolean;
}

export const ShopCardSelector = ({type, name, subtype, color, onClick, active}: ShopCardSelectorProps) => {
    const {t} = useTranslation();
    return (
        <div className={"ShopCardSelector px-[1px] sm:px-1"}>
            <div className={"ShopCardButton w-full relative"}>
                <button
                    className={clsx(
                        "flex flex-col relative rounded-xl lg:rounded-4xl w-full p-1 px-[4px] py-2 lg:px-2 lg:py-3 2xl:px-4 2xl:py-4 z-50 no-underline active:no-underline focus:no-underline hover:no-underline" +
                        " active:text-orange-800 focus:text-orange-800 hover:text-orange-800 justify-center items-center select-none",
                        active && "text-orange-800",
                    )}
                    type="button"
                    onClick={() => onClick(name)}
                >
                    <div className={clsx(
                        "z-50 lg:w-40 flex flex-col",
                        isMobileOnly ? "lg:w-40" : "sm:w-40"
                    )}>
                        <ShopItemText text={t(type)} />
                        <MutedText className={"tracking-tighter flex-grow break-words"}
                                   text={t(subtype)} />
                    </div>
                </button>
                <CardBackground background={active ? "" : ""} rounded={"rounded-xl lg:rounded-4xl"}
                                shadow={active ? "shadow-md shadow-black" : "shadow-lg"} />
            </div>
        </div>
    );
};
