import React from "react";
import clsx from "clsx";
import {isMobileOnly} from "react-device-detect";

interface ShopItemsTypeListProps {
    children: React.ReactNode[] | React.ReactNode;
    title: React.ReactNode[] | React.ReactNode;
}

export const ShopItemsTypeList = (props: ShopItemsTypeListProps) => {
    return (
        <div className={clsx(
            "shopItemsTypeList flex flex-col space-y-2 lg:space-y-4 select-none",
            isMobileOnly ? "items-center lg:items-start lg:mt-2" : "items-center sm:items-start sm:mt-2"
        )}>
            <div>{props.title}</div>
            <div className={clsx(
                "flex gap-1 sm:space-x-0 lg:space-y-1 2xl:space-y-4 gap-[1px]",
                isMobileOnly ? "flex-row lg:flex-col" : "md:space-y-1 flex-row sm:flex-col"
            )}>{props.children}</div>
        </div>
    );
};
