import React from "react";
import clsx from "clsx";
import {getPersonTypeColorClass} from "utils/utility";
import {Profile} from "types/entities/Profile";

interface CardProfileImageProps {
    profile: Profile;

}
const CardProfileImage = (props: CardProfileImageProps) => {
    return (
        <div className="relative flex w-full h-full items-center opacity-100 justify-center">
            <div className="relative flex w-48 h-48 sm:w-24 sm:h-24 2xl:w-36 2xl:h-36 items-center justify-center rounded-full">
                <div className={clsx(getPersonTypeColorClass(props.profile.person_type), "relative w-full h-full flex justify-center items-center rounded-full bg-opacity-30 z-2")}>
                    <div className="h-[86%] w-[86%] flex overflow-hidden justify-center items-center rounded-full bg-gradient-to-tr from-white to-gray-200 ">
                        <img className="z-3 w-full h-full rounded-full object-cover object-center" loading="lazy" src={props.profile.profile_picture_url ?? "/"} />
                    </div>
                </div>
                <div className="absolute left-0 top-auto right-0 bottom-auto rounded-extreme opacity-40 filter blur-[50px] bg-dark-800 h-15 "></div>
            </div>
        </div>
    );
};

export default CardProfileImage;
