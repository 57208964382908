import React, {useEffect, useState} from "react";
import {useDispatch} from "useDispatch";
import {useTypedSelector} from "helpers/reducers";
import {VoucherItem} from "types/entities/VoucherItem";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import VoucherAction from "store/shop/VoucherAction";
import clsx from "clsx";
import {TicketIcon} from "@heroicons/react/24/solid";
import {SubmitButton} from "components/Buttons/Buttons";
import {MutedText} from "pages/Front/components/TextStyles/MutedText";
import {currencyNumberFormat} from "utils/utility";
import ShoppingCartVoucherItem from "pages/Front/Shop/components/ShoppingCartVoucherItem";
import {fetchDeleteVoucherAndApply, fetchVoucherAndApply} from "utils/shoppingCartUtils";
import BookingAction from "store/booking/BookingAction";
import {VOUCHERS_LIMIT} from "helpers/config";
import {ShopArticleItemsVouchersRequest} from "types/entities/ShopArticleItemsVouchersRequest";

const ShoppingCartVoucher = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const articleItems = useTypedSelector(state => state.shopState.articleItems);
    const voucherItems = useTypedSelector(state => state.voucherState.voucherItems);
    const appliedVoucherItems = useTypedSelector(state => state.voucherState.appliedVoucherItems);
    const itemsCart = useTypedSelector(state => state.shopState.itemsCart);
    const toastId = "ShoppingCartVoucher";
    const [applyPending, setApplyPending] = useState(false);

    const defaultValues = {
        code: "",
    };
    const {
        register,
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: defaultValues,
    });

    const deleteVoucherAndApplyArticleItemsHandler = (code: string) => {
        deleteVoucherHandler(code);
        if (itemsCart.shopArticleItemsCart) {
            const data = {
                code: code,
                shop_article_items_cart_id: itemsCart.shopArticleItemsCart.id,
                hall_id: itemsCart.shopArticleItemsCart.hall_id,
            };
            fetchDeleteVoucherAndApply(dispatch, data);
        }
    };

    const deleteVoucherHandler = (code: string) => {
        const voucherItemToDelete = voucherItems.find(voucherItem => voucherItem.code === code);
        const appliedItemToDelete = appliedVoucherItems.find(voucherItem => voucherItem.code === code);
        if (articleItems && appliedItemToDelete) {
            dispatch(VoucherAction.deleteAppliedVoucherItem(appliedItemToDelete));
        }

        if (voucherItemToDelete) {
            dispatch(VoucherAction.deleteVoucherItem(voucherItemToDelete));
        }
    };

    const fetchGetVoucher = (data, dispatch) => {
        return dispatch(VoucherAction.fetchGetVoucher(data))
            .then(r => {
                if (r.voucher) {
                    dispatch(VoucherAction.setVoucherItem(r.voucher));
                }
                if (r.packageCart) {
                    dispatch(BookingAction.setPackageCart(r.packageCart));
                }
                return r;
            })
            .catch(err => {
                toast.error(err);
            });
    };

    const reapplyVoucherHandler = (voucher) => {
        const shopArticleItemsCart = itemsCart.shopArticleItemsCart;

        if (itemsCart && shopArticleItemsCart) {
            const newData: ShopArticleItemsVouchersRequest = {
                code: voucher.code,
                shop_article_items_cart_id: shopArticleItemsCart.id,
                hall_id: shopArticleItemsCart.hall_id,
            };

            setApplyPending(true);
            fetchVoucherAndApply(dispatch, newData, setApplyPending);
            reset();

            return;
        }
    }

    const submitFormHandler = data => {
        if (voucherItems.length >= VOUCHERS_LIMIT) {
            toast.warning(t("Only one coupon can be applied 2"), {
                toastId: toastId,
            });
            return;
        }
        if (data.code.length <= 2) {
            toast.warning(t("Code has to be filled in"), {
                toastId: toastId,
            });
            return;
        }
        if (data.code.trim().split(" ").length - 1 > 0) {
            toast.warning(t("Code has to be filled in without spaces"), {
                toastId: toastId,
            });
            return;
        }
        if (typeof articleItems === "undefined" || articleItems == null) {
            fetchGetVoucher(data, dispatch);
            //ends here
            return;
        }
        const shopArticleItemsCart = itemsCart.shopArticleItemsCart;

        if (itemsCart && shopArticleItemsCart) {
            const newData: ShopArticleItemsVouchersRequest = {
                code: data.code,
                shop_article_items_cart_id: shopArticleItemsCart.id,
                hall_id: shopArticleItemsCart.hall_id,
            };

            setApplyPending(true);
            fetchVoucherAndApply(dispatch, newData, setApplyPending);
            reset();

            return;
        }

        toast.error(t("Cart is not valid"), {
            toastId: toastId,
        });
    };

    const applyVouchersHandler = () => {
        return voucherItems.forEach(function (voucherItem) {
            // fetchVoucherAndApply(dispatch, voucherItem, articleItems, setApplyPending);
        });
    };

    useEffect(() => {
        if (articleItems.length > 0) {
            applyVouchersHandler();
        }
    }, [articleItems]);

    return (
        <div className={"ShoppingCartVoucher flex flex-col justify-end grow divide-y divide-gray-500"}>
            <section>
                <div className={clsx(voucherItems.length > 0 ? "max-h-[1000px]" : "max-h-0", "transform-gpu duration-500 ease-out pb-2")}>
                    {((voucherItems && voucherItems.length > 0) || (appliedVoucherItems && appliedVoucherItems.length > 0)) && (
                        <div className={"space-y-3"}>
                            <div className={"divide-y divide-gray-600 space-y-1"}>
                                {voucherItems.map((voucherItem: VoucherItem) => {
                                    const foundVoucher = appliedVoucherItems.find(appliedVoucherItem => appliedVoucherItem.code === voucherItem.code);
                                    if (voucherItem === null) {
                                        return false;
                                    }
                                    return (
                                        <div key={voucherItem.id} className={"py-1"}>
                                            <ShoppingCartVoucherItem
                                                foundVoucher={typeof foundVoucher !== "undefined"}
                                                reapplyVoucher={() => reapplyVoucherHandler(voucherItem)}
                                                voucherItem={voucherItem}
                                                onClick={() => deleteVoucherAndApplyArticleItemsHandler(voucherItem.code)}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <section className={"ShoppingCartVoucherForm"}>
                <div className={"flex flex-row justify-between py-2"}>
                    <MutedText className={"text-xs font-medium tracking-wider"} text={t("Total discount")} />
                    <div className="text-xl font-bold font-i text-black">{currencyNumberFormat(itemsCart.shopArticleItemsCart?.discount_value ?? 0)}</div>
                </div>
                <form className={"z-40 flex flex-row justify-between space-x-2"} onSubmit={handleSubmit(submitFormHandler)}>
                    <input
                        className={"shrink w-1/2 border border-dark-800 px-1 rounded-md placeholder:text-gray-700"}
                        placeholder={t("Voucher code")}
                        type={"text"}
                        {...register("code")}
                    />
                    <SubmitButton
                        icon={<TicketIcon className={"w-4 h-4 mr-2 -ml-1"} />}
                        text={t("Enter code")}
                        type={"submit"}
                        onClick={handleSubmit(submitFormHandler)}
                    />
                </form>
            </section>
        </div>
    );
};

export default ShoppingCartVoucher;
