import {Referral, ReferralClaimData, ReferralsConvertData} from "types/app";
import {createAction} from "helpers/createAction";
import ajax from "helpers/ajax";
import {Profile} from "types/entities/Profile";
import {
    ReferralReducerDeleteReferralAction,
    ReferralReducerPushReferralAction,
    ReferralReducerPushReferralsAction,
    ReferralReducerSetReferralsAction,
} from "store/referrals/ReferralReducer";
import {VoucherItem} from "types/entities/VoucherItem";
import {FriendInvitation} from "types/entities/FriendInvitation";
class ReferralAction {
    static readonly PUSH_REFERRAL = "ReferralAction.PushReferral";
    static readonly DELETE_REFERRAL = "ReferralAction.DeleteReferral";
    static readonly PUSH_REFERRALS = "ReferralAction.PushReferrals";
    static readonly SET_REFERRALS = "ReferralAction.SetReferrals";
    static readonly CLEAR_REFERRALS = "ReferralAction.ClearReferrals";

    static pushReferral = (referral: Referral): ReferralReducerPushReferralAction => {
        return createAction(ReferralAction.PUSH_REFERRAL, referral);
    };

    static setReferrals = (referrals: Referral[]): ReferralReducerSetReferralsAction => {
        return createAction(ReferralAction.SET_REFERRALS, referrals);
    };

    static pushReferrals = (referrals: Referral[]): ReferralReducerPushReferralsAction => {
        return createAction(ReferralAction.PUSH_REFERRALS, referrals);
    };

    static deleteReferral = (referral: Referral): ReferralReducerDeleteReferralAction => {
        return createAction(ReferralAction.DELETE_REFERRAL, referral);
    };

    static clearReferrals = (payload: [] = []) => {
        return createAction(ReferralAction.CLEAR_REFERRALS, payload);
    };




    static fetchRemoveReferral = (referral: Referral) => {
        return async (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;
            try {
                const r = await ajax.delete("/referrals/delete/" + referral.guid, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                });
                return r;
            } catch (err: any) {
                return err;
            }
        };
    };

    static inviteFriendByEmail = (email: string, app_url: string, withReferral: boolean = false) => {
        if (email === null || email === "") {
            return Promise.reject();
        }

        return async (dispatch, getState): Promise<FriendInvitation> => {
            const session = getState().sessionState.session;
            try {
                return await ajax.post(
                    "/referrals/invite-friend",
                    {email, app_url, withReferral},
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${session.access_token}`,
                        },
                    }
                );
            } catch (err: any) {
                return err;
            }
        };
    };

    static fetchViewReferral = (code: string) => {
        if (code === null || code === "") {
            return Promise.reject();
        }

        return async (): Promise<Referral> => {
            try {
                const r = await ajax.post("/referrals/view", {code});
                return r;
            } catch (err: any) {
                return err;
            }
        };
    };

    static fetchConvertReferrals = (data: ReferralsConvertData) => {
        if (data.referral_codes.length === 0) {
            return Promise.reject();
        }
        return async (dispatch, getState): Promise<VoucherItem> => {
            const session = getState().sessionState.session;
            try {
                return await ajax.post("/referrals/convert", data, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                });
            } catch (err: any) {
                return err;
            }
        };
    };

    static fetchAcceptReferral = (data: ReferralClaimData) => {
        if (data.claimed_driver_id === null || data.code === null) {
            return Promise.reject();
        }
        return async (dispatch, getState): Promise<Referral> => {
            const session = getState().sessionState.session;
            try {
                return await ajax.post("/referrals/accept", data, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                });
            } catch (err: any) {
                return err;
            }
        };
    };
    static fetchGetReferrals = (profile: Profile) => {
        if (profile === null) {
            return Promise.reject();
        }
        return async (dispatch, getState): Promise<Referral[]> => {
            const session = getState().sessionState.session;
            try {
                return await ajax.get("/referrals/list", {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                });
            } catch (err: any) {
                return err;
            }
        };
    };

    static fetchCreateReferral = (profile: Profile) => {
        if (profile === null) {
            return Promise.reject();
        }
        return async (dispatch, getState): Promise<Referral> => {
            const session = getState().sessionState.session;
            try {
                return await ajax.get("/referrals/create", {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                });
            } catch (err: any) {
                return err;
            }
        };
    };
}

export default ReferralAction;
