import React from "react";
import {useDispatch} from "react-redux";
import clsx from "clsx";
import {useTypedSelector} from "helpers/reducers";
import {languages} from "helpers/config";
import {toast} from "react-toastify";
import SessionAction from "store/session/SessionAction";

const LanguageSelector = ({language, name, closeLanguage}) => {
    const hallLanguage = useTypedSelector(state => state.hallState.hall?.language.code);
    const activeLanguage = useTypedSelector(state => state.sessionState.language?.code) || hallLanguage;

    if (!activeLanguage) {
        return null;
    }
    const dispatch = useDispatch();

    const setLanguage = (languageCode: string) => {
        const newLanguage = languages.find(lang => lang.code === languageCode);

        if (!newLanguage) {
            toast.error("Lang error");
            return;
        }

        dispatch(SessionAction.setLanguage(newLanguage));
        closeLanguage();
        // window.location.reload();
    };
    return (
        <div
            className={clsx(
                "LanguageSelector relative w-full  flex flex-col justify-center items-center cursor-pointer hover:text-cta-800 focus:text-cta-800 active:text-cta-800",
                language === activeLanguage ? "text-cta-800" : ""
            )}
            onClick={() => setLanguage(language)}
        >
            <div className="relative flex w-full h-full justify-center items-center z-3 flex-col p-2">
                <div className="font-medium tracking-tight text-lg">{name}</div>
            </div>
        </div>
    );
};

export default LanguageSelector;
