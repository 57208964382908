class PwaHelper {
    deferred: null | BeforeInstallPromptEvent = null;

    setDeferredEvent = (value: BeforeInstallPromptEvent | null) => {
        this.deferred = value;
    };

    beforeInstallationPrompt = (): boolean => {
        const hasPromptAvailable = typeof (window as any).beforeinstallprompt !== "undefined";
        // @ts-ignore TS2339: Property 'beforeinstallprompt' does not exist on type 'Window & typeof globalThis'.
        window.beforeinstallprompt = (e: BeforeInstallPromptEvent) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.setDeferredEvent(e);
        };
        return hasPromptAvailable;
    };

    initInstallation = e => {
        if (!this.deferred) {
            return;
        }

        // Show the prompt
        this.deferred.prompt();
        // Wait for the user to respond to the prompt
        this.deferred.userChoice.then(choiceResult => {
            if (choiceResult.outcome === "accepted") {
                console.log("User accepted the A2HS prompt");
            } else {
                console.log("User dismissed the A2HS prompt");
            }
            this.setDeferredEvent(null);
        });
    };
}
const Pwa = new PwaHelper();
export default Pwa;
