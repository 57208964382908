import React from "react";

interface Props {
    children: React.ReactNode | React.ReactNode[]
}

const DashboardOneColumnLayout = (props: Props) => {
    return (
        <div className="DashboardOneColumnLayout relative flex w-full h-full flex-row justify-stretch flex-wrap content-between items-stretch">
            <div className="relative flex w-full h-full flex-col justify-start py-2 pb-2 px-0 lg:px-0 lg:pt-2 lg:pb-2 items-center">{props.children}</div>
        </div>
    );
};

export default DashboardOneColumnLayout;
