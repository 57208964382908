import {BookingTimeslot, ReservationDriver} from "types/app";
import ReservationAction from "store/reservations/ReservationAction";
import {Reservation} from "types/entities/Reservation";
import update from "immutability-helper";
import {PackagesTimeSlots} from "store/booking/BookingAction";
import {PackagesCart} from "types/entities/PackagesCart";
import {Package} from "types/entities/Package";
import Dates from "utils/Dates";
import {Activity} from "types/entities/Activity";
import {Timeslot} from "types/entities/Timeslot";
import {ReducerResetAction} from "store/session/SessionReducer";
import SessionAction from "store/session/SessionAction";
import {Paginator} from "pages/Front/ReservationHistory/ReservationHistory";

export interface TCreateReservationDriver {
    newDriver: ReservationDriver,
    oldDriver?: ReservationDriver
}

export interface ReservationSetReservationDriverAction {
    payload: ReservationDriver;
    type: typeof ReservationAction.SET_RESERVATION_DRIVER;
}

export interface ReservationDeleteReservationDriverAction {
    payload: ReservationDriver;
    type: typeof ReservationAction.DELETE_RESERVATION_DRIVER;
}

// export interface ReservationSetToggleSelectReservationDriverAction {
//     payload: ReservationDriver;
//     type: typeof ReservationAction.SET_TOGGLE_SELECT_RESERVATION_DRIVER;
// }

export interface ReservationSetReservationTimeslotsAction {
    payload: BookingTimeslot[];
    type: typeof ReservationAction.SET_TIMESLOTS;
}

export interface ReservationSetToggleSelectedDriverAction {
    payload: ReservationDriver;
    type: typeof ReservationAction.SET_TOGGLE_SELECT_RESERVATION_DRIVER;
}

export interface ReservationSetReservationDriverRowAction {
    payload: {driver: ReservationDriver, identifier: number};
    type: typeof ReservationAction.SET_RESERVATION_DRIVER_ROW;
}

export interface ReservationSetCurrentNavigationStepAction {
    payload: Step;
    type: typeof ReservationAction.SET_CURRENT_NAVIGATION_STEP;
}

export interface ReservationSetPackageAction {
    payload: Package;
    type: typeof ReservationAction.SET_PACKAGES;
}

export interface ReservationSetReservationFilterAction {
    payload: PackagesTimeSlots;
    type: typeof ReservationAction.SET_RESERVATION_FILTER;
}

export interface ReservationToggleSelectedTimeslotAction {
    payload: Timeslot;
    type: typeof ReservationAction.TOGGLE_SELECTED_TIMESLOT;
}

export interface ReservationSetPackagesCartAction {
    payload: PackagesCart;
    type: typeof ReservationAction.SET_PACKAGES_CART;
}

export interface ReservationSetActivitiesAction {
    payload: Activity[];
    type: typeof ReservationAction.SET_ACTIVITIES;
}

export interface ReservationResetAction {
    payload: Reservation | null;
    type: typeof ReservationAction.RESET_RESERVATION;
}

export interface ReservationSetReservationsList {
    payload: Paginator<Reservation>;
    type: typeof ReservationAction.SET_RESERVATIONS_LIST;
}

export interface ReservationSetActiveReservationsList {
    payload: Paginator<Reservation>;
    type: typeof ReservationAction.SET_ACTIVE_RESERVATIONS_LIST;
}

export interface ReservationSetReservationResult {
    payload: Reservation;
    type: typeof ReservationAction.SET_RESERVATION_RESULT;
}

export interface ReservationSetReservation {
    payload: Reservation;
    type: typeof ReservationAction.SET_RESERVATION;
}


type ReservationReducerAction = ReservationSetReservationDriverAction
    | ReservationDeleteReservationDriverAction
    // | ReservationSetToggleSelectReservationDriverAction
    | ReservationSetReservationTimeslotsAction
    | ReservationSetToggleSelectedDriverAction
    | ReservationSetReservationDriverRowAction
    | ReservationSetCurrentNavigationStepAction
    | ReservationSetPackageAction
    | ReservationSetReservationFilterAction
    | ReservationToggleSelectedTimeslotAction
    | ReservationSetPackagesCartAction
    | ReservationSetActivitiesAction
    | ReservationResetAction
    | ReducerResetAction
    | ReservationSetActiveReservationsList
    | ReservationSetReservationsList
    | ReservationSetReservationResult
    | ReservationSetReservation;

export const STEPS = [
    {
        id: 1,
        name: "choose racers",
    },
    {
        id: 2,
        name: "choose races",
    },
    {
        id: 3,
        name: "summary",
    },
    {
        id: 4,
        name: "confirmation",
    },
];

export interface Step {
    id: number,
    name: string
}

export interface ReservationReducerState {
    drivers: ReservationDriver[],
    reservations: any,
    active_reservations: any,
    reservation: Reservation | null,
    result: Reservation | null,
    bookingTimeslots: BookingTimeslot[],
    selectedTimeslots: Timeslot[],
    filter: PackagesTimeSlots,
    packagesCart: PackagesCart | null,
    package: Package | null,
    activities: Activity[]
    navigation: {
        currentStep: Step,
        availableSteps: Step[]
    }
}

// {
//     data: [],
//         current_page: 0,
//     from: 1,
//     last_page: 1,
//     last_page_url: "",
//     links: [],
//     next_page_url: null,
//     path: "",
//     per_page: "10",
//     prev_page_url: null,
//     to: 10,
//     total: 0,
// },
class ReservationReducer {
    initialState: ReservationReducerState = {
        drivers: [],
        reservations: [],
        active_reservations: [],
        reservation: null,
        result: null,
        bookingTimeslots: [],
        selectedTimeslots: [],
        filter: {
            adults: 0,
            children: 0,
            date: Dates.ymd(new Date()),
            package_id: 1, // package_id by selected activity -> for now only karting,
            upsell: [],
        },
        packagesCart: null,
        package: null,
        activities: [],
        navigation: {
            currentStep: STEPS[0],
            availableSteps: STEPS,
        },
    };

    reducer = (state: ReservationReducerState = this.initialState, action: ReservationReducerAction): ReservationReducerState => {
        switch (action.type) {
            case ReservationAction.RESET_RESERVATION:
                return this.initialState;

            case ReservationAction.SET_RESERVATION_DRIVER:
                return update(state, {
                    drivers: {
                        $push: [action.payload],
                    },
                });
            case ReservationAction.DELETE_RESERVATION_DRIVER:
                const filteredDrivers = state.drivers.filter(driver => driver.identifier !== action.payload.identifier);

                return update(state, {
                    drivers: {
                        $set: filteredDrivers,
                    },
                });
            case ReservationAction.SET_TIMESLOTS:
                return update(state, {
                    bookingTimeslots: {
                        $set: action.payload,
                    },
                });
            case ReservationAction.SET_TOGGLE_SELECT_RESERVATION_DRIVER:
                const driverIndexRow = state.drivers.findIndex(driver => driver.identifier === action.payload.identifier);

                if (driverIndexRow === -1) {
                    return state;
                }

                return update(state, {
                    drivers: {
                        [driverIndexRow]: {
                            selected: {
                                $set: action.payload.selected,
                            },
                        },
                    },
                });
            case ReservationAction.SET_RESERVATION_DRIVER_ROW:
                const driverRowIndex = state.drivers.findIndex(driver => driver.identifier === action.payload.identifier);

                if (driverRowIndex === -1) {
                    return state;
                }

                return update(state, {
                    drivers: {
                        [driverRowIndex]: {
                            $set: action.payload.driver,
                        },
                    },
                });
            case ReservationAction.SET_CURRENT_NAVIGATION_STEP:
                return update(state, {
                    navigation: {
                        currentStep: {
                            $set: action.payload,
                        },
                    },
                });
            case ReservationAction.SET_PACKAGES:
                return update(state, {
                    package: {
                        $set: action.payload,
                    },
                });
            case ReservationAction.SET_RESERVATION_FILTER:
                return update(state, {
                    filter: {
                        $set: action.payload,
                    },
                });
            // case ReservationAction.SET_TOGGLE_SELECT_RESERVATION_DRIVER:
            //     const drivers = state.drivers.filter(driver => driver.identifier === action.payload.identifier);
            //
            //     return update(state, {
            //         drivers: {
            //             $set: drivers
            //         }
            //     })
            case ReservationAction.TOGGLE_SELECTED_TIMESLOT:
                return update(state, {
                    selectedTimeslots: {
                        $set: [action.payload],
                    },
                });
            case ReservationAction.SET_PACKAGES_CART:
                return update(state, {
                    packagesCart: {
                        $set: action.payload,
                    },
                });
            case ReservationAction.SET_ACTIVITIES:
                return update(state, {
                    activities: {
                        $set: action.payload,
                    },
                });
            case ReservationAction.SET_RESERVATIONS_LIST:
                return update(state, {
                    reservations: {
                        $set: action.payload,
                    },
                },
            );
            case ReservationAction.SET_ACTIVE_RESERVATIONS_LIST:
                return update(state, {
                        active_reservations: {
                            $set: action.payload,
                        },
                    },
                );
            case ReservationAction.SET_RESERVATION_RESULT:
                return update(state, {
                    // navigation: {
                    //     $set: this.initialState.navigation
                    // },
                    selectedTimeslots: {
                        $set: this.initialState.selectedTimeslots,
                    },
                    result: {
                        $set: action.payload,
                    },
                });
            case ReservationAction.SET_RESERVATION:
                return update(state, {
                    reservation: {
                        $set: action.payload,
                    },
                });

            case SessionAction.RESET:
                return this.initialState;

            default:
                return state;
        }
    };
}

export default ReservationReducer;