import React from "react";
import {useTypedSelector} from "helpers/reducers";
import CartHelper from "helpers/CartHelper";
import {useDispatch} from "useDispatch";
import { confirmCart, confirmShopArticleItemsCart } from "helpers/actions";
import CardBackgroundStripes from "pages/Front/components/CardBackgroundStripes";
import ShopCartItem from "./ShopCartItem";
import {TitleText} from "pages/Front/components/TextStyles/TitleText";
import {useTranslation} from "react-i18next";
import {SubmitButton} from "components/Buttons/Buttons";
import {MutedText} from "pages/Front/components/TextStyles/MutedText";
import {currencyNumberFormat} from "utils/utility";
import {ShoppingCartIcon} from "@heroicons/react/24/solid";
import {toast} from "react-toastify";
import ShoppingCartVoucher from "pages/Front/Shop/components/ShoppingCartVoucher";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {ShopArticleItem} from "types/entities/ShopArticleItem";

interface ShopCartProps {
    cartActive?: boolean;
    setCartActive: (isActive: boolean) => void;
}

export const ShopCart = ({cartActive, setCartActive}: ShopCartProps) => {
    const itemsCart = useTypedSelector(state => state.shopState.itemsCart);
    const articleState = useTypedSelector(state => state.articlesState);
    if (articleState.all) {
        CartHelper.setArticles(articleState.all);
    }

    const totalPrice =itemsCart.shopArticleItemsCart?.price || 0;
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const checkout = e => {
        e.preventDefault();
        if (itemsCart.shopArticleItems.length > 0 && itemsCart.shopArticleItemsCart !== null) {
            dispatch(confirmShopArticleItemsCart("/shop/paid/", itemsCart.shopArticleItemsCart.id)).then(r => {
            });
        } else {
            toast.warning(t("Your shopping cart is empty"));
        }
    };

    return (
        <div className="ShopCart absolute inset-0 overflow-hidden z-50">
            <div className="relative w-full h-full flex flex-col justify-start items-center z-50">
                <div className="relative w-full h-full z-50">
                    <div className="p-8 flex flex-col justify-center items-center w-full h-full">
                        <div className={"absolute left-4 top-4 right-auto z-50"}>
                            <button className={"p-4"} type={"button"} onClick={() => setCartActive(false)}>
                                <div className={"rounded-full w-6 h-6 p-1 bg-white shadow-lg"}>
                                    <XMarkIcon className={"w-4 h-4"} />
                                </div>
                            </button>
                        </div>
                        <div className="flex-shrink w-full flex flex-col justify-center items-center relative">
                            <TitleText text={t("Your cart")} />
                        </div>
                        <div className="ShopCartForm w-full h-full flex flex-col flex-grow justify-between space-y-2 sm:space-y-4 divide-y divide-gray-500 z-[100]">
                            <div className={"flex-grow flex flex-col grow justify-start"}>
                                <div className={"grow flex flex-col relative overflow-y-auto"}>
                                    <div className={"absolute inset-0 flex flex-col"}>
                                        <ul className="flex flex-col divide-gray-500 divide-y border-b border-gray-500" role="list">
                                            {itemsCart.shopArticleItems.map((shopArticleItem: ShopArticleItem) => (
                                                <ShopCartItem key={shopArticleItem.id} shopArticleItem={shopArticleItem} />
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className={"shrink-0"}>
                                    <ShoppingCartVoucher />
                                </div>
                            </div>

                            <form className="flex flex-col" data-name="ShopCardForm" id="email-form-3" name="email-form-3">
                                <div className="ShopCartFooter flex-shrink flex-col">
                                    <div className="flex flex-row justify-between py-2">
                                        <MutedText className={"text-xs font-medium tracking-wider"} text={t("Sum")} />
                                        <div className="text-xl font-bold font-i text-black">{currencyNumberFormat(totalPrice)}</div>
                                    </div>
                                    <div className={"flex flex-row items-end justify-end"}>
                                        <div className={"basis-[50%] flex"}>
                                            <SubmitButton
                                                icon={<ShoppingCartIcon className={"w-4 h-4 mr-2 -ml-2"} />}
                                                text={t("Checkout")}
                                                type={"submit"}
                                                onClick={checkout}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <CardBackgroundStripes rounded={"rounded-tl-3xl rounded-bl-3xl"} />
            </div>
        </div>
    );
};
