import { TrashIcon} from "@heroicons/react/24/solid";
import {TicketIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {getValidityByWeekDay, voucherTypeByArticle} from "utils/voucherUtils";
import React from "react";
import {VoucherItem} from "types/entities/VoucherItem";
import {useTranslation} from "react-i18next";
import VoucherItemDateValidity from "pages/Front/components/VoucherItemDateValidity";

interface VoucherItemProps {
    voucherItem: VoucherItem;
    foundVoucher?: boolean;
    onClick: () => void;
    reapplyVoucher: () => void;
}

const ShoppingCartVoucherItem = (props: VoucherItemProps) => {
    const {t} = useTranslation();
    const getValidityByWeekDayObject = getValidityByWeekDay(props.voucherItem, new Date().toString());
    return (
        <article
            key={props.voucherItem.id}
            className={"ShoppingCartVoucherItem relative flex flex-col items-center justify-center w-full"}
            data-active={props.foundVoucher}
            data-voucher-id={props.voucherItem.id}
            title={props.foundVoucher ? t("Active voucher") : t("Inactive voucher")}
        >
            <div className={"flex flex-row relative items-stretch shadow rounded-2xl overflow-hidden justify-between w-full"}>
                <button className={"flex justify-center items-center text-white bg-dark-800 p-2"} onClick={props.reapplyVoucher}>
                    {props.voucherItem.amount_left === 0 ? <XMarkIcon className={"w-8 h-8"} /> : <TicketIcon className={"w-8 h-8"} />}
                </button>
                <div className={"flex flex-col items-start w-full grow p-1 bg-gray-300 bg-opacity-50"}>
                    <div className={"relative flex flex-row items-center border-b w-full border-gray-700 p-1"}>
                        <dt className="text-base font-normal text-gray-900">{props.voucherItem.code}</dt>
                        <VoucherItemDateValidity voucherItemValidity={getValidityByWeekDayObject} />
                    </div>
                    <div className={"flex flex-row items-center justify-between w-full p-1"}>
                        <div className="flex items-baseline text-xl font-semibold text-dark-800">
                            {voucherTypeByArticle(props.voucherItem).valueFormatted}
                            <span className="ml-2 text-sm font-medium text-gray-800">{t(voucherTypeByArticle(props.voucherItem).label)}</span>
                        </div>

                        <button
                            className={"w-6 h-6 flex items-center justify-center mt-1 ml-2 hover:text-red-700 "}
                            title={t("Delete voucher")}
                            type="button"
                            onClick={props.onClick}
                        >
                            <TrashIcon className={"w-5 h-5 text-inherit"} />
                        </button>
                    </div>
                    {/*{props.voucherItem.amount_left === 0 && (*/}
                    {/*    <div className={"flex flex-row items-center justify-between w-full p-1"}>*/}
                    {/*        <span className={"text-sm font-medium text-red-700"}>{t("Voucher invalid")}</span>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            </div>
        </article>
    );
};

export default ShoppingCartVoucherItem;
