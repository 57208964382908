import T from "components/T";
import React, {useEffect} from "react";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/Loader";
import {useTranslation} from "react-i18next";
import MyStatsItem from "./MyStatsItem";
import CardTag from "../pages/Front/components/CardTag";
import CardContainer from "../pages/Front/components/CardContainer";
import SessionAction from "store/session/SessionAction";
import {useDispatch} from "useDispatch";

interface MyStatsCardProps {
    isMobile: boolean;
}

const MyStatsCard = (props: MyStatsCardProps) => {
    const profile = useTypedSelector(state => state.sessionState.profile);
    const hall = useTypedSelector(state => state.hallState.hall);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (profile && profile.home_hall_id) {
            dispatch(SessionAction.fetchMyStats(profile.home_hall_id));
        }
    }, []);

    if (!profile || !hall) {
        return <Loader />;
    }

    return (
        <div className="MyStatsCard col-span-3 flex w-full z-50 relative justify-end self-end items-center rounded-2xl flex-col pb-12 sm:pb-0">
            {!props.isMobile && (
                <CardContainer>
                    <CardTag label={t("Stats")} />
                    <div className={"w-full flex flex-col 2xl:flex-row items-center justify-between"}>
                        <div className={"shrink-0 grow-0"}>
                            {hall.is_extendable_track === 1 ? (
                                <div className="grid grid-cols-2 lg:grid-cols-2 mt-2 w-full justify-start grid-rows-auto">
                                    <MyStatsItem
                                        isMobile={props.isMobile}
                                        number={profile.personalStats ? profile.personalStats.best_laptime / 100 : 0}
                                        svg={"best-time"}
                                        text={<T>Outer track</T>}
                                        type="time"
                                    />
                                    <MyStatsItem
                                        isMobile={props.isMobile}
                                        number={profile.personalStats ? profile.personalStats.best_laptime_inner_track / 100 : 0}
                                        svg={"best-time"}
                                        text={<T>Inner track</T>}
                                        type="time"
                                    />
                                </div>
                            ) : (
                                <div className="grid grid-cols-1 lg:grid-cols-1 2xl:mt-2 w-full justify-start grid-rows-auto">
                                    <MyStatsItem
                                        isMobile={props.isMobile}
                                        number={profile.personalStats ? profile.personalStats.best_laptime / 100 : 0}
                                        svg={"best-time"}
                                        text={t("Your record")}
                                        type="time"
                                    />
                                </div>
                            )}
                        </div>
                        {/*<MyStatsItem*/}
                        {/*    isMobile={props.isMobile}*/}
                        {/*    number={profile.personalStats?.rank_place || 0}*/}
                        {/*    svg={"ranking_1"}*/}
                        {/*    text={<T>Place</T>}*/}
                        {/*    type={"position"}*/}
                        {/*/>*/}
                        <div className="grow grid grid-cols-4 mt-2 w-full justify-start">
                            <MyStatsItem
                                isMobile={props.isMobile}
                                number={profile.personalStats?.coins_sum ? profile.personalStats?.coins_sum : 0}
                                svg={"tomcoin"}
                                text={<T>Coins</T>}
                            />
                            <MyStatsItem
                                isMobile={props.isMobile}
                                number={profile.personalStats?.podiums ? profile.personalStats?.podiums[0] : 0}
                                svg={"ranking_1"}
                                text={<T>1st</T>}
                            />
                            <MyStatsItem
                                isMobile={props.isMobile}
                                number={profile.personalStats?.podiums ? profile.personalStats?.podiums[1] : 0}
                                svg={"ranking_1"}
                                text={<T>2nd</T>}
                            />
                            <MyStatsItem
                                isMobile={props.isMobile}
                                number={profile.personalStats?.podiums ? profile.personalStats?.podiums[2] : 0}
                                svg={"ranking_1"}
                                text={<T>3rd</T>}
                            />
                        </div>
                    </div>
                </CardContainer>
            )}
            {props.isMobile && (
                <CardContainer>
                    <div className={"w-full flex flex-row flex-wrap gap-y-2 justify-between"}>
                        {hall.is_extendable_track === 1 ? (
                            <>
                                <MyStatsItem
                                    isMobile={props.isMobile}
                                    number={profile.personalStats ? profile.personalStats.best_laptime_outer_track / 100 : 0}
                                    svg={"best-time"}
                                    text={<T>Outer track</T>}
                                    type="time"
                                />
                                <MyStatsItem
                                    isMobile={props.isMobile}
                                    number={profile.personalStats ? profile.personalStats.best_laptime_inner_track / 100 : 0}
                                    svg={"best-time"}
                                    text={<T>Inner track</T>}
                                    type="time"
                                />
                            </>
                        ) : (
                            <MyStatsItem
                                isMobile={props.isMobile}
                                number={profile.personalStats ? profile.personalStats.best_laptime / 100 : 0}
                                svg={"best-time"}
                                text={t("Best lap")}
                                type="time"
                            />
                        )}
                        <MyStatsItem
                            isMobile={props.isMobile}
                            number={profile.personalStats?.coins_sum ? profile.personalStats?.coins_sum : 0}
                            svg={"tomcoin"}
                            text={<T>Coins</T>}
                        />
                        <MyStatsItem
                            isMobile={props.isMobile}
                            number={profile.personalStats?.podiums ? profile.personalStats?.podiums[0] : 0}
                            svg={"ranking_1"}
                            text={<T>1st</T>}
                        />
                        <MyStatsItem
                            isMobile={props.isMobile}
                            number={profile.personalStats?.podiums ? profile.personalStats?.podiums[1] : 0}
                            svg={"ranking_1"}
                            text={<T>2nd</T>}
                        />
                        <MyStatsItem
                            isMobile={props.isMobile}
                            number={profile.personalStats?.podiums ? profile.personalStats?.podiums[2] : 0}
                            svg={"ranking_1"}
                            text={<T>3rd</T>}
                        />
                    </div>
                </CardContainer>
            )}
        </div>
    );
};

export default MyStatsCard;
