import React from "react";
import T from "components/T";
import SkillBall from "./SkillBall";
import {Profile} from "types/entities/Profile";

interface CardProfileSpeedLevelProps {
    profile: Profile;
}

const CardProfileSpeedLevel = (props: CardProfileSpeedLevelProps) => {
    return (
        <div className="flex flex-col mt-2 2xl:mt-4 justify-between">
            <div className="font-i text-sm font-normal inline-flex">
                <T>Speed Level</T>
                <div className="ml-4">
                    {props.profile.speed_level}
                    <span className="opacity-50">/10</span>
                </div>
            </div>
            <div className="flex justify-center mt-2">
                <SkillBall full={2} speedLevel={props.profile.speed_level} />
                <SkillBall full={4} speedLevel={props.profile.speed_level} />
                <SkillBall full={6} speedLevel={props.profile.speed_level} />
                <SkillBall full={8} speedLevel={props.profile.speed_level} />
                <SkillBall full={10} speedLevel={props.profile.speed_level} />
            </div>
        </div>
    );
};

export default CardProfileSpeedLevel;
