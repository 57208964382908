import React, {FC, InputHTMLAttributes} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

interface GlobalInputProps extends InputHTMLAttributes<HTMLInputElement> {
    register: any;
    name: any;
    label: string;
    htmlFor: any;
    type: string;
    placeholder: string;
    required: boolean;
    autofocus: boolean;
    minLength: number;
    minLengthMessage: string;
    pattern: string;
    patternMessage: string;
}

const GlobalInput: FC<GlobalInputProps> = ({
                                               name,
                                               label,
                                               type,
                                               required,
                                               autofocus,
                                               minLength,
                                               minLengthMessage,
                                               pattern,
                                               patternMessage,
                                               htmlFor,
                                               placeholder,
                                               register,
                                               ...rest
                                           }) => {
    const {t} = useTranslation();
    // @ts-ignore

    return (
        <div>
            <label
                className={"absolute z-10 -mt-3 ml-3 py-1 px-3 bg-white border rounded-lg border-gray-400 text-xs font-medium leading-3 font-i"}
                htmlFor={name}
            >
                {t(label)}
            </label>
            <input
                {...register(name, {
                    required: required,
                    pattern: {
                        value: pattern,
                        message: patternMessage,
                    },
                    minLength: {
                        value: minLength,
                        message: minLengthMessage,
                    },
                })}
                autoCapitalize={"none"}
                autoComplete={(name === "password" ? "current-password" : "username")}
                autoFocus={autofocus}
                className="relative z-1 h-10 min-h-[50px] mb-0 pt-[11px] border-gray-600 valid:border-green-500 rounded-lg placeholder-gray-700 border block w-full py-2 px-3 text-sm leading-6 text-gray-800 bg-white"
                id={name}
                name={name}
                placeholder={placeholder}
                required={required}
                type={type}
            />
        </div>
    );
};
export default connect()(GlobalInput);
