import {VoucherItem} from "types/entities/VoucherItem";
import VoucherAction from "store/shop/VoucherAction";
import update from "immutability-helper";

export interface VoucherReducerPushVoucherItemsAction {
    payload: VoucherItem;
    type: typeof VoucherAction.PUSH_VOUCHER_ITEMS;
}

export interface VoucherReducerSetVoucherItemsAction {
    payload: VoucherItem;
    type: typeof VoucherAction.SET_VOUCHER_ITEM;
}

export interface VoucherReducerDeleteVoucherItemsAction {
    payload: VoucherItem;
    type: typeof VoucherAction.DELETE_VOUCHER_ITEM;
}

export interface VoucherReducerDeleteAppliedVoucherItemsAction {
    payload: VoucherItem;
    type: typeof VoucherAction.DELETE_APPLIED_VOUCHER_ITEM;
}

export interface VoucherReducerDeleteAppliedVoucherItemsAction {
    payload: VoucherItem;
    type: typeof VoucherAction.DELETE_APPLIED_VOUCHER_ITEM;
}

export interface VoucherReducerResetVoucherItemsAction {
    payload: any;
    type: typeof VoucherAction.RESET_VOUCHER;
}

export interface VoucherReducerResetAppliedVoucherItemsAction {
    payload: any;
    type: typeof VoucherAction.RESET_APPLIED_VOUCHER;
}

export interface VoucherReducerApplyVoucherItemsAction {
    payload: VoucherItem;
    type: typeof VoucherAction.APPLY_VOUCHER_ITEMS;
}

export interface VoucherReducerSetAllVouchersItemsAction {
    payload: VoucherItem[];
    type: typeof VoucherAction.SET_ALL_VOUCHERS_ITEMS;
}

export interface VoucherReducerSelectVoucherItemAction {
    payload: VoucherItem;
    type: typeof VoucherAction.SELECT_VOUCHER_ITEM;
}

type VoucherReducerAction =
     VoucherReducerPushVoucherItemsAction
    | VoucherReducerSelectVoucherItemAction
    | VoucherReducerDeleteVoucherItemsAction
    | VoucherReducerDeleteAppliedVoucherItemsAction
    | VoucherReducerResetVoucherItemsAction
    | VoucherReducerResetAppliedVoucherItemsAction
    | VoucherReducerApplyVoucherItemsAction
    | VoucherReducerSetVoucherItemsAction
    | VoucherReducerSetAllVouchersItemsAction;

export interface VoucherReducerState {
    voucherItems: VoucherItem[];
    selectedVoucherItems: VoucherItem[],
    appliedVoucherItems: VoucherItem[];
    selectedVoucher: VoucherItem | null;
}

class VoucherReducer {
    initialState: VoucherReducerState = {
        voucherItems: [],
        selectedVoucherItems: [],
        appliedVoucherItems: [],
        selectedVoucher: null,
    };

    reducer = (state: VoucherReducerState = this.initialState, action: VoucherReducerAction): VoucherReducerState => {
        switch (action.type) {
            case VoucherAction.PUSH_VOUCHER_ITEMS:
                return update(state, {
                    voucherItems: {
                        $push: [action.payload],
                    },
                });


            case VoucherAction.SET_ALL_VOUCHERS_ITEMS:
                return update(state, {
                    selectedVoucherItems: {
                        $set: action.payload,
                    },
                });


            case VoucherAction.SELECT_VOUCHER_ITEM:
                return update(state, {
                    selectedVoucher: {
                        $set: action.payload,
                    },
                });

            case VoucherAction.SET_VOUCHER_ITEM:
                // @ts-ignore
                if (action.payload !== null) {
                    const voucherIndex = state.voucherItems.findIndex(voucher => {
                        return voucher.code === action.payload.code;
                    });
                    if (voucherIndex === -1) {
                        if (action.payload !== null) {
                            return update(state, {
                                voucherItems: {
                                    $push: [action.payload],
                                },
                            });
                        }
                    }

                    // @ts-ignore
                    return update(state, {
                        voucherItems: {
                            [voucherIndex]: {
                                article_id: {
                                    $set: action.payload.article_id,
                                },
                            },
                        },
                    });
                }

                return state;

            case VoucherAction.RESET_APPLIED_VOUCHER:
                return update(state, {
                    appliedVoucherItems: {
                        $set: [],
                    },
                });
            case VoucherAction.RESET_VOUCHER:
                return update(state, {
                    selectedVoucherItems: {
                        $set: [],
                    },
                    voucherItems: {
                        $set: [],
                    },
                    appliedVoucherItems: {
                        $set: [],
                    },
                });

            case VoucherAction.APPLY_VOUCHER_ITEMS:
                // @ts-ignore
                if (action.payload !== null) {
                    const voucherIndex = state.appliedVoucherItems.findIndex(voucher => {
                        return voucher.code === action.payload.code;
                    });
                    if (voucherIndex === -1) {
                        if (action.payload !== null) {
                            return update(state, {
                                appliedVoucherItems: {
                                    $push: [action.payload],
                                },
                            });
                        }
                    }

                    // @ts-ignore
                    return update(state, {
                        appliedVoucherItems: {
                            [voucherIndex]: {
                                article_id: {
                                    $set: action.payload.article_id,
                                },
                            },
                        },
                    });
                }

                return state;

            case VoucherAction.DELETE_VOUCHER_ITEM:
                const removeVoucherIndex = state.voucherItems.findIndex(voucher => voucher.code === action.payload.code);

                if (removeVoucherIndex === -1) {
                    return state;
                }

                return update(state, {
                    voucherItems: {
                        $splice: [[removeVoucherIndex, 1]],
                    },
                });

            case VoucherAction.DELETE_APPLIED_VOUCHER_ITEM:
                const removeAppliedVoucherIndex = state.appliedVoucherItems.findIndex(voucher => voucher.code === action.payload.code);

                if (removeAppliedVoucherIndex === -1) {
                    return state;
                }

                return update(state, {
                    appliedVoucherItems: {
                        $splice: [[removeAppliedVoucherIndex, 1]],
                    },
                });

            default:
                return state;
        }
    };
}

export default VoucherReducer;
