import ajax from "helpers/ajax";
import {createAction} from "helpers/createAction";
import {ThunkAction} from "model/store/thunk";
import {Hall} from "types/entities/Hall";
import {Language} from "types/entities/Language";
import i18next from "i18next";
import SessionAction from "store/session/SessionAction";

class HallAction {
    static readonly SET_HALL = "HallAction.SetHall";
    static readonly SET_HALLS_LIST = "HallAction.SetHallsList";
    static readonly SET_LANGUAGE = "HallAction.SetLanguage";

    static fetchHall = (id?: number): ThunkAction<Promise<Response>, any> => {
        return (dispatch): Promise<any> => {
            return ajax.get("/halls" + (id ? "/detail/" + id : "/home")).then(r => {
                dispatch(HallAction.setHall(r.hall));
                return r;
            });
        };
    }

    static fetchHalls = (): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            return ajax.get("/halls/list").then(r => {
                dispatch(HallAction.setHallsList(r.halls));
            });
        };
    }

    static updateHomeHallId = (id): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            return ajax
                .post("/user/update-profile", {
                    home_hall_id: id,
                })
                .then(r => {
                    dispatch(SessionAction.fetchMe());
                    //dispatch(fetchRanking());
                    dispatch(SessionAction.fetchProfile());
                    window.location.reload();
                    return r;
                });
        };
    }

    static setHall = (hall: Hall) => {
        return createAction(HallAction.SET_HALL, hall);
    }

    static setHallsList = (hallsList: Hall[]) => {
        return createAction(HallAction.SET_HALLS_LIST, hallsList);
    }


    static setLanguage = (language: Language) => {
        i18next.changeLanguage(language.code).then(r => {
        });

        return createAction(HallAction.SET_LANGUAGE, language);
    }
}

export default HallAction;