import SessionAction from "store/session/SessionAction";
import {SessionData} from "types/responses/SessionResponse";
import update from "immutability-helper";
import {Profile} from "types/entities/Profile";
import {Driver} from "types/entities/Driver";
import {Language} from "types/entities/Language";
import {Ticket} from "types/entities/Ticket";
import {MyStats} from "types/entities/MyStats";
import {Notification} from "types/entities/Notification";

export interface ReducerSessionSetSessionAction {
    payload: SessionData;
    type: typeof SessionAction.SET_SESSION;
}

export interface ReducerSessionPushNotificationAction {
    payload: Notification;
    type: typeof SessionAction.PUSH_NOTIFICATION;
}

export interface ReducerSessionSetNotificationsAction {
    payload: Notification[];
    type: typeof SessionAction.SET_NOTIFICATIONS;
}

export interface ReducerSessionSetMyStatsAction {
    payload: MyStats;
    type: typeof SessionAction.SET_MYSTATS;
}

export interface ReducerSessionSetProfileAction {
    payload: Profile;
    type: typeof SessionAction.SET_PROFILE;
}

export interface ReducerSessionSetTicketsAction {
    payload: Ticket[];
    type: typeof SessionAction.SET_TICKETS;
}

export interface ReducerSessionSetRankingAction {
    payload: any;
    type: typeof SessionAction.SET_RANKING;
}

export interface ReducerSessionSetResultAction {
    payload: any;
    type: typeof SessionAction.SET_RESULT;
}

export interface ReducerSessionSetResultsAction {
    payload: any[];
    type: typeof SessionAction.SET_RESULTS;
}

export interface ReducerResetAction {
    payload: null;
    type: typeof SessionAction.RESET;
}

export interface ReducerSessionSetCheckinAction {
    payload: Driver;
    type: typeof SessionAction.SET_CHECKIN;
}

export interface ReducerSessionSetLanguageAction {
    payload: Language;
    type: typeof SessionAction.SET_LANGUAGE;
}

type ReducerSessionAction =
    | ReducerSessionSetNotificationsAction
    | ReducerSessionPushNotificationAction
    | ReducerSessionSetSessionAction
    | ReducerSessionSetProfileAction
    | ReducerSessionSetMyStatsAction
    | ReducerSessionSetTicketsAction
    | ReducerSessionSetRankingAction
    | ReducerSessionSetResultAction
    | ReducerSessionSetCheckinAction
    | ReducerSessionSetLanguageAction
    | ReducerSessionSetResultsAction
    | ReducerResetAction;

export interface SessionState {
    notifications: Notification[];
    session: SessionData | null;
    profile: Profile | null;
    ranking: any | null;
    result: any;
    results: any[];
    language: Language | null;
    tickets: Ticket[] | [];
    myStats: MyStats;
}

class SessionReducer {
    initialState: SessionState = {
        notifications: [],
        session: null,
        profile: null,
        ranking: null,
        result: null,
        results: [],
        language: null,
        tickets: [],
        myStats: {
            bestLap: [],
            me: null,
            stats: [],
        },
    };

    reducer = (state: SessionState = this.initialState, action: ReducerSessionAction): SessionState => {
        switch (action.type) {
            case SessionAction.PUSH_NOTIFICATION:
                const notificationIndex = state.notifications.findIndex(notification => notification.id === action.payload.id);
                if (notificationIndex !== -1) {
                    return update(state, {
                        notifications: {
                            [notificationIndex]: {
                                $set: action.payload,
                            },
                        },
                    });
                }
                return update(state, {
                    notifications: {
                        $push: [action.payload],
                    },
                });

            case SessionAction.SET_NOTIFICATIONS:
                return update(state, {
                    notifications: {
                        $set: action.payload,
                    },
                });

            case SessionAction.SET_SESSION:
                return update(state, {
                    session: {
                        $set: action.payload,
                    },
                });
            case SessionAction.SET_CHECKIN:
                return update(state, {
                    profile: {
                        checkin_time: {
                            $set: action.payload.checkin_time,
                        },
                        checked: {
                            $set: action.payload.checked,
                        },
                    },
                });
            case SessionAction.SET_RESULT:
                return update(state, {
                    result: {
                        $set: action.payload,
                    },
                });
            case SessionAction.SET_RANKING:
                return update(state, {
                    ranking: {
                        $set: action.payload,
                    },
                });
            case SessionAction.SET_RESULTS:
                return update(state, {
                    results: {
                        $set: action.payload,
                    },
                });

            case SessionAction.SET_PROFILE:
                return update(state, {
                    profile: {
                        $set: action.payload,
                    },
                });

            case SessionAction.SET_MYSTATS:
                return update(state, {
                    myStats: {
                        $set: action.payload,
                    },
                });
            case SessionAction.SET_TICKETS:
                return update(state, {
                    tickets: {
                        $set: action.payload,
                    },
                });

            case SessionAction.SET_LANGUAGE:
                return update(state, {
                    language: {
                        $set: action.payload,
                    },
                });
            case SessionAction.RESET:
                return this.initialState;

            default:
                return state;
        }
    };
}

export default SessionReducer;
