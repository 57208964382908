import React, {useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router";
import "pages/Front/Login/Login.scss";
import WidgetProfile from "../components/WidgetProfile";
import {useTypedSelector} from "helpers/reducers";
import T from "components/T";
import DashboardWidgetRides from "./components/DashboardWidgetRides";
import Loader from "components/Loader";
import MyStatsCard from "components/MyStatsCard";
import clsx from "clsx";
import DashboardWidgetSlider from "pages/Front/Dashboard/components/DashboardWidgetSlider";
import DashboardHeader from "./components/DashboardHeader";
import DashboardTwoColumnLayout from "../components/DashboardTwoColumnLayout";
import {useTranslation} from "react-i18next";
import DashboardOneColumnLayout from "../components/DashboardOneColumnLayout";
import AreaSwitch from "../../../components/AreaSwitch";
import DashboardTag from "../components/DashboardTag";
import {isMobileOnly} from "react-device-detect";
import Pwa from "helpers/PwaHelper";
import {CloudArrowDownIcon} from "@heroicons/react/24/outline";

interface Params {
}

interface DashboardProps extends RouteComponentProps<Params> {
}

const Dashboard = (props: DashboardProps) => {
    const profile = useTypedSelector(state => state.sessionState.profile);
    const {t} = useTranslation();
    const session = useTypedSelector(state => state.sessionState.session);
    const [tabActive, setTabActive] = useState<string>("overview");
    const [showInstallMessageIOS, setShowInstallMessageIOS] = useState<boolean>();
    const [showInstallMessageOthers, setShowInstallMessageOthers] = useState<boolean>();
    // const hintActive = useTypedSelector(state => state.hintActive);

    useEffect(() => {
        // dispatch(SessionAction.fetchProfile());
    }, [session]);

    useEffect(() => {
        // Detects if device is on iOS
        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        };

        // @ts-ignore navigator is type never
        const isInStandaloneMode = "standalone" in window.navigator && window.navigator.standalone;

        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode) {
            setShowInstallMessageIOS(true);
        } else {
            if (Pwa.beforeInstallationPrompt()) {
                setShowInstallMessageOthers(true);
            }
        }
    }, []);

    const addAppToHomeScreen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        Pwa.initInstallation(e);
        setShowInstallMessageOthers(false);
    };

    const logoutHandler = () => {
        window.location.replace("/logout");
    };

    if (!profile) {
        return <Loader />;
    }
    return (
        <div
            className={clsx("Dashboard flex relative z-2 justify-start w-full flex-grow flex-col items-stretch max-w-9xl")}>
            {!isMobileOnly && (
                <div className={"DashboardContent DesktopOnly"}>
                    <DashboardHeader subtitle={<T>Overview</T>} title={t("Hi") + ` ${profile.name},`}
                                     titleSuffix={<T>Welcome Back!</T>} />

                    <DashboardTwoColumnLayout
                        contentLeft={
                            <>
                                <DashboardWidgetRides count={profile.ticket_races_left_adult} title={<T>Adult</T>}
                                                      type={"adult"} />
                                <DashboardWidgetRides count={profile.ticket_races_left_child} title={<T>Children</T>}
                                                      type={"child"} />
                                <DashboardWidgetRides
                                    count={(profile.ticket_races_left_adult_action && profile.ticket_races_left_child_action) ? (profile.ticket_races_left_adult_action + profile.ticket_races_left_child_action) : 0}
                                    hint={t(
                                        "All three journeys must be redeemed on the day of purchase. The tickets are personal and non-transferable. The Promotion does not apply to 5 or 10 subscriptions and does not apply to public holidays such as Easter Monday or Whit Monday, etc.",
                                    )}
                                    title={<T>Action</T>}
                                    type={"action-" + profile.person_type}
                                />
                                <DashboardWidgetSlider />
                            </>
                        }
                        contentRight={
                            <>
                                <WidgetProfile />
                                <MyStatsCard isMobile={isMobileOnly} />

                                {/*<WidgetReferrals />*/}
                            </>
                        }
                    />
                </div>
            )}
            {isMobileOnly && (
                <div className={"DashboardContent MobileOnly"}>
                    {showInstallMessageIOS && (
                        <div className="ios-prompt">
                            <span
                                style={{
                                    color: "rgb(187, 187, 187)",
                                    float: "right",
                                    marginTop: "-14px",
                                    marginRight: "-11px",
                                }}
                            >
                                ×
                            </span>
                            <img
                                src="assets/imgs/add2home.svg"
                                style={{
                                    float: "left",
                                    height: "80px",
                                    width: "auto",
                                    marginTop: "-8px",
                                    marginRight: "1rem",
                                }}
                            />
                            <p style={{marginTop: "-3px", lineHeight: "1.3rem"}}>
                                To install this Web App in your iPhone/iPad press{" "}
                                <img
                                    src="assets/imgs/share.svg"
                                    style={{
                                        display: "inline-block",
                                        marginTop: "4px",
                                        marginBottom: "-4px",
                                        height: "20px",
                                        width: "auto",
                                    }}
                                />{" "}
                                and then Add to Home Screen.
                            </p>
                        </div>
                    )}
                    {showInstallMessageOthers && (
                        <div className={"fixed bg-red-400 p-2 top-12 z-1 inset-x-0"}>
                            <button className={"w-full flex flex-row items-center"} id="btnPwa"
                                    onClick={addAppToHomeScreen}>
                                <CloudArrowDownIcon className={"w-5 h-5 mr-2"} />
                                <span>{t("Install app onto home screen")}</span>
                            </button>
                        </div>
                    )}
                    <DashboardHeader subtitle={<T>Overview</T>} title={t("Hi") + ` ${profile.name},`}
                                     titleSuffix={<T>Welcome Back!</T>} />

                    <DashboardOneColumnLayout>
                        <React.Fragment>
                            <div className={"flex flex-row w-full px-0 my-2 justify-self-start items-center"}>
                                <AreaSwitch
                                    initialActive={tabActive}
                                    items={[
                                        {name: "Overview", value: "overview", disabled: false},
                                        {name: "Profile", value: "profile", disabled: false},
                                    ]}
                                    onClick={item => setTabActive(item.value)}
                                />
                            </div>
                            {tabActive == "profile" && (
                                <div
                                    className={"DashboardMobileProfile flex flex-col items-center grow w-full space-y-4 pb-12"}>
                                    <WidgetProfile />
                                    {/*<WidgetReferrals />*/}
                                    <button
                                        className={
                                            "flex flex-row relative z-1 w-full max-w-[16rem]  h-14 px-4 py-2 bg-blue-custom rounded-2xl text-white text-base uppercase justify-center items-center opacity-90 bg-blue-custom-shadow font-bold"
                                        }
                                        onClick={logoutHandler}
                                    >
                                        {t("Logout")}
                                    </button>
                                </div>
                            )}
                            {tabActive == "overview" && (
                                <div className={"DashboardMobileOverview flex flex-col space-y-4"}>
                                    <div className={"DashboardWidgetTag flex w-screen px-4 "}>
                                        <DashboardTag label={t("Available races")} withArrow={true} />
                                    </div>
                                    <div
                                        className={"DashboardWidgetRidesList flex w-screen px-4 pb-8 space-x-4 overflow-y-visible overflow-x-scroll"}>
                                        <DashboardWidgetRides count={profile.ticket_races_left_adult}
                                                              title={<T>Adult</T>} type={"adult"} />
                                        <DashboardWidgetRides count={profile.ticket_races_left_child}
                                                              title={<T>Children</T>} type={"child"} />
                                        <DashboardWidgetRides
                                            count={(profile.ticket_races_left_adult_action && profile.ticket_races_left_child_action) ? (profile.ticket_races_left_adult_action + profile.ticket_races_left_child_action) : 0}
                                            hint={t(
                                                "All three journeys must be redeemed on the day of personal and non-transferable. The Promotion purchase. The tickets are does not apply to 5 or 10 subscriptions and does not apply to public holidays such as Easter Monday or Whit Monday, etc.",
                                            )}
                                            title={<T>Action</T>}
                                            type={"action-" + profile.person_type}
                                        />
                                    </div>
                                    <div className={"DashboardWidgetRest flex flex-col px-4 w-screen"}>
                                        <div className={"DashboardWidgetSliderCard flex w-full pt-4 pb-6"}>
                                            <DashboardWidgetSlider />
                                        </div>
                                        <div className={"DashboardMyStatsCard flex flex-col w-full mt-4 space-y-4"}>
                                            <DashboardTag label={t("Your stats")} />
                                            <MyStatsCard isMobile={isMobileOnly} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    </DashboardOneColumnLayout>
                </div>
            )}
        </div>
    );
};
export default withRouter(Dashboard);
