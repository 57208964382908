import React from "react";
import clsx from "clsx";
import Blur from "./Blur";

interface CardBackgroundProps {
    rounded?: string;
    shadow?: string;
    background?: string;
}

const CardBackground = (props: CardBackgroundProps) => {
    return (
        <div className={"CardBackground group select-none pointer-events-none highlight-none "}>
            <div
                className={clsx(
                    "CardBackground  select-none pointer-events-none highlight-none  overflow-hidden absolute inset-0 z-[1]",
                    props.rounded,
                    props.shadow ? props.shadow : "shadow-lg"
                )}
            >
                <div className="mbm-soft-light softlight opacity-20 absolute inset-0 bg-gradient-to-tl from-black to-white" />
                <div
                    className={clsx(props.background ? props.background : "bg-white", "absolute inset-0 z-1 bg-gradient-to-br from-transparent to-[#dcdcdc40]")}
                />
            </div>

            <div className="CardBackgroundShadow absolute select-none pointer-events-none highlight-none  inset-x-0 top-auto -mx-4 h-12 transform-gpu -bottom-5 group-hover:-bottom-8 overflow-visible text-dark-800 text-opacity-80">
                <Blur />
            </div>
        </div>
    );
};

export default CardBackground;
