import React from "react";
import Slider from "pages/Front/components/Slider";
import SliderItem from "pages/Front/components/SliderItem";
import {Link} from "react-router-dom";
import SliderOffer from "../../components/SliderOffer";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/Loader";
import {PersonType} from "types/enums/PersonType";
import {ArticleType} from "types/enums/ArticleType";
import {currencyNumberFormat, getArticlePrices} from "utils/utility";
import {useTranslation} from "react-i18next";
import CardBackground from "pages/Front/components/CardBackground";
import {PriceOffer} from "types/entities/PriceOffer";

const DashboardWidgetSlider = () => {
        const profile = useTypedSelector(state => state.sessionState.profile);
        const articlesState = useTypedSelector(state => state.articlesState);
        const personType = profile?.person_type;
        const {t} = useTranslation();
        if (!profile || !articlesState.all) {
            return <Loader />;
        }

        const getArticleTypeText = (type: ArticleType) => {
            switch (type) {
                case ArticleType.Online:
                    return t("Per ride");
                case ArticleType.Action:
                    return t("3 races for");
                default:
                    return t("Per ride");
            }
        };
        const getArticlePriceByType = (type: ArticleType, personType: PersonType, count: number) => {
            const priceOffer: PriceOffer = getArticlePrices(articlesState, type, personType, count);
            return getArticleTypeText(type) + " " + currencyNumberFormat(priceOffer.price)
        };

        return (
            <div
                className="DashboardWidgetSlider relative group outline-none w-full max-h-[140px] flex flex-col justify-center h-full grow col-span-3">
                <Slider delay={4000}>
                    <SliderItem>
                        <Link to={`/shop/action-${personType}`}>
                            <SliderOffer primary={t("Buy more action tickets")}
                                         secondary={getArticlePriceByType(ArticleType.Action, personType, 3)}
                                         type="ticket" />
                        </Link>
                    </SliderItem>
                    <SliderItem>
                        <Link to={`/shop/child`}>
                            <SliderOffer primary={t("Buy more children races")}
                                         secondary={getArticlePriceByType(ArticleType.Online, PersonType.Child, 1)}
                                         type="ticket" />
                        </Link>
                    </SliderItem>
                    {/*<SliderItem>*/}
                    {/*    <Link to="/shop/adult">*/}
                    {/*        <SliderOffer primary={t("Buy more adult races")}*/}
                    {/*                     secondary={getArticlePriceByType(ArticleType.Online, PersonType.Adult, 1)}*/}
                    {/*                     type="ticket" />*/}
                    {/*    </Link>*/}
                    {/*</SliderItem>*/}
                </Slider>
                <div className={"absolute inset-0 rounded-3xl z-2 group-hover:bg-opacity-90 group-focus:bg-opacity-90 bg-dark-800"} />
                <CardBackground background={"bg-dark-800"} rounded={"rounded-4xl"} />
            </div>
        );
    }
;
export default DashboardWidgetSlider;
