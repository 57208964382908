import React, {useLayoutEffect, useState} from "react";
import clsx from "clsx";

interface SkillBallProps {
    speedLevel: number;
    full: number;
}

const SkillBall = (props: SkillBallProps) => {
    const timeout = props.full * 100;
    const [loading, setLoading] = useState(false);
    useLayoutEffect(() => {
        const timer = setTimeout(() => {
            setLoading(true);
        }, timeout);
        return () => {
            clearTimeout(timer);
        };
    }, []);
    return (
        <div className="SkillBall relative flex overflow-hidden w-5 h-5 mr-1 justify-center items-center rounded-full z-60 shadow-multiInset">
            <div className="absolute inset-0 w-full h-full bg-gradient-to-br from-gray-700 to-gray-200 z-10" />
            {props.speedLevel >= props.full && (
                <div
                    className={clsx(
                        !loading ? "max-w-0" : "max-w-full",
                        "absolute transform-gpu transition-all rounded-full duration-500 delay-500 inset-0 w-full h-full bg-gradient-to-r from-orange-300 to-orange-400 z-50"
                    )}
                />
            )}
            {props.speedLevel + 1 === props.full && (
                <div
                    className={clsx(
                        !loading ? "max-w-0" : "max-w-full",
                        "absolute transform-gpu transition-all rounded-tl-full rounded-bl-full duration-500 delay-500 inset-0 w-1/2 h-full bg-gradient-to-r from-orange-300 to-orange-400  z-50"
                    )}
                />
            )}
        </div>
    );
};

export default SkillBall;
