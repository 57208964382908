import React from "react";

interface ShopArticlesProps {
    count: number;
}

export const ShopArticlesCount = (props: ShopArticlesProps) => {
    return (
        <div className={"absolute select-none pointer-events-none text-white z-50 flex top-4 right-1 -translate-y-1/2"}>
            <div
                className={"flex flex-col font-bold text-sm items-center justify-center w-4 h-4 bg-red-500 p-1 rounded-full"}>
                {props.count}
            </div>
        </div>
    )
}