import React from "react";

const SuspenseLoader = props => {
    return (
        <>
            <div className={["SuspenseLoader", props.naked && "naked"].join(" ")} style={{zIndex: 120}}>
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </>
    );
};

export default SuspenseLoader;
