import T from "components/T";
import React from "react";

interface DashboardTagProps {
    label: string;
    withArrow?: boolean;
}

const DashboardTag = (props: DashboardTagProps) => {
    return (
        <div className="DashboardTag flex pl-1 justify-between w-full items-center opacity-90 text-black self-start">
            <h1 className="font-m text-sm font-bold uppercase">
                <T>{props.label}</T>
            </h1>
            {props.withArrow && (
                <div className={"w-8 h-8 flex justify-center items-end"}>
                    <svg
                        className="w-full h-full object-cover"
                        fill="none"
                        height="36"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        width="36"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M18 15l3-3-3-3" strokeLinecap="round"></path>
                        <path d="M3 12h18" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </div>
            )}
        </div>
    );
};

export default DashboardTag;
