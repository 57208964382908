import React from "react";
import T from "components/T";
import SupportForm from "pages/Front/components/SupportForm";
import {useTypedSelector} from "helpers/reducers";
import {ServerImage} from "components/ServerImage";
import {useTranslation} from "react-i18next";

interface Props {
    closeModal?: () => void;
}

const SupportPopup = (props: Props) => {
    const hall = useTypedSelector(state => state.hallState.hall);
    const {t} = useTranslation();
    return (
        <div className="SupportPopup w-full max-w-2xl relative z-50 min-w-[300px]">
            <div
                className="relative flex overflow-hidden w-full h-full flex-row justify-center items-start z-50"
                style={{
                    transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                    opacity: 1,
                }}
            >

                <div
                    className="grid p-4 sm:p-8 items-stretch gap-x-8 gap-y-4 sm:gap-y-10 w-full bg-white rounded-3xl sm:grid-cols-2 relative">
                    <div className={"absolute left-0 top-0"}>
                        <button className={"p-4"} type={"button"} onClick={props.closeModal}>
                            <div className={"rounded-full w-6 h-6 px-2 py-1 bg-red-400 shadow-lg"}>
                                <ServerImage alt={t("Close cart")} src="/img/icon-X.svg" />
                            </div>
                        </button>
                    </div>
                    <div>
                        <div className="w-full sm:mb-7">
                            <div className="font-k text-2xl sm:text-4xl italic font-bold uppercase">
                                <T>Do you have any questions?</T>
                            </div>
                        </div>
                        <div>
                            <div className="mb-2 sm:mb-7">
                                <div className="font-m text-sm sm:text-base sm:leading-5 font-normal">
                                    <T>Call Us</T>
                                </div>
                                <a
                                    className="font-m text-sm sm:text-base sm:leading-5 font-light text-blue-400"
                                    href={`tel:${hall && hall.contact ? hall.contact : "+41 78 730 16 43"}`}
                                >
                                    {hall && hall.contact ? hall.contact : "+41 78 730 16 43"}
                                </a>
                            </div>
                            <div className="mb-2 sm:mb-7">
                                <div className="font-m text-sm sm:text-base sm:leading-5 font-normal">
                                    <T>Email Us</T>
                                </div>
                                <a className="font-m text-sm sm:text-base sm:leading-5 font-light text-blue-400"
                                   href="mailto:info@tomkart.ch?subject=Support">
                                    {hall && hall.email ? hall.email : "info@tomkart.ch"}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="self-center">
                        <SupportForm />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SupportPopup;
