import React from "react";

const ShopTwoColumnLayout = props => {
    const {contentLeft, contentRight} = props;
    return (
        <div className="ShopTwoColumnLayout relative flex grow flex-row justify-stretch flex-wrap content-between items-stretch mr-16 2xl:mr-20">
            <div className={"max-w-[220px] flex-grow-0 flex-shrink-0 pt-4 2xl:pt-8 items-stretch justify-items-stretch overflow-x-hidden"}>
                {contentLeft}
            </div>
            <div className={"grow shrink-0 flex justify-items-stretch lg:h-full items-start flex-col pt-4 2xl:pt-8 mr-0 lg:mr-[184px] 2xl:mr-[200px]"}>
                <div className="gap-8 flex flex-col w-full h-full items-center justify-start ">{contentRight}</div>
            </div>
        </div>
    );
};

export default ShopTwoColumnLayout;
