import React, {ReactNode} from "react";

interface ImageBubbleProps {
    children: ReactNode;
}

const ImageBubble = (props: ImageBubbleProps) => {
    return (
        <div className="flex w-full h-full p-1 justify-center rounded-full items-center">
            <div className="w-full h-full text-gray-700">{props.children}</div>
        </div>
    );
};

export default ImageBubble;
