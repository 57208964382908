import React from "react";

const SliderItem = props => {
    return (
        <div
            className="SliderItem relative whitespace-normal text-left no-underline"
            style={{
                top: 0,
                transform: props.active ? "translateX(0px)" : "translateX(-720px)",
                width: props.active ? "100%" : 0,
                height: props.active ? "100%" : 0,
                visibility: props.active ? "visible" : "hidden",
                opacity: props.active ? 1 : 0,
                zIndex: props.active ? 399 : 398,
                transition: "opacity 600ms ease-in-out 0s",
            }}
        >
            <div className="relative z-1 flex w-full h-full px-6 py-4 sm:p-4 2xl:p-6 box-border flex-row outline-none no-underline justify-start items-start ">
                <div className="flex flex-row align-middle items-center justify-start content-start grow">{props.children}</div>
            </div>
        </div>
    );
};
export default SliderItem;
