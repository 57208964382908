import React, {useLayoutEffect, useState} from "react";
import CardBackground from "pages/Front/components/CardBackground";
import {ServerImage} from "components/ServerImage";
import {useTypedSelector} from "helpers/reducers";
import {ShopArticlesCount} from "pages/Front/Shop/components/ShopArticlesCount";
import clsx from "clsx";

interface ShopCartToggleIconProps {
    setCartActive: () => void;
}

export const ShopCartToggleIcon = (props: ShopCartToggleIconProps) => {
    const shopArticles = useTypedSelector(state => state.shopState.itemsCart.shopArticleItems);
    const [updated, setUpdated] = useState(false);
    useLayoutEffect(() => {
        setUpdated(true);
        const timer = setTimeout(() => {
            setUpdated(false);
        }, 750);
        return () => {
            clearTimeout(timer);
        };
    }, [shopArticles]);

    return (
        <div className="ShopCartToggleIcon fixed top-1/2 bottom-1/2 left-auto right-0 pb-16 items-center flex">
            <div className={"relative translate-y-1/2"}>
                <button
                    className={clsx(
                        "relative  flex flex-col justify-start items-center p-6 z-50 rounded-tl-3xl rounded-bl-3xl rounded-bl-3xl",
                        updated && "bg-red-500 bg-opacity-50"
                    )}
                    onClick={props.setCartActive}
                >
                    <ShopArticlesCount count={shopArticles.length} />
                    <div className="relative z-2 flex flex-col w-full h-full items-center justify-start">
                        <div className="w-5 h-5">
                            <ServerImage alt="" src="/img/basket.svg" />
                        </div>
                    </div>
                </button>
                <CardBackground rounded={"rounded-bl-3xl rounded-tl-3xl"} />
            </div>
        </div>
    );
};
