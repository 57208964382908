import React, {useEffect} from "react";
import "pages/Front/Login/Login.scss";
import {withRouter} from "react-router";
import {useDispatch} from "useDispatch";
import {clearCache, clearLocalStorage} from "helpers/store";
import SessionAction from "store/session/SessionAction";

const Logout = withRouter(props => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(SessionAction.logout()).then(r => {
            clearLocalStorage();
            clearCache();
        });
        props.history.push("/");
    }, []);

    return null;
});
export default Logout;
