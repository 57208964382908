import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import store from "helpers/store";
import langFr from "i18n/fr/resource.json";
import langDe from "i18n/de/resource.json";
import langEn from "i18n/en/resource.json";
import langIt from "i18n/it/resource.json";
import langNl from "i18n/nl/resource.json";
import langHu from "i18n/hu/resource.json";
import {DEFAULT_LANGUAGE} from "helpers/config";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: langEn,
    },
    de: {
        translation: langDe,
    },
    fr: {
        translation: langFr,
    },
    it: {
        translation: langIt,
    },
    nl: {
        translation: langNl,
    },
    hu: {
        translation: langHu,
    },
};

const lang = store.getState().sessionState?.profile?.lang || DEFAULT_LANGUAGE;
i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        returnEmptyString: false,
        fallbackLng: false,
        lng: lang,
        debug: false,
        nsSeparator: false,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        //formatFallbackMessages: true
    });

export default i18n;
