const Styles = {
    modal: {
        overlay: {
            backgroundColor: "hsla(0,0%,42%,.81)",
            zIndex: 5000,
        },
        content: {
            width: "90%",
            height: "90%",
            top: "50%",
            left: "50%",
            overflow: "auto",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "80vh",
            maxWidth: "80vw",
            zIndex: 50000,
        },
    },
};

export default Styles;
