import VoucherAction, { VoucherResponse } from "store/shop/VoucherAction";
import ShopAction from "store/shop/ShopAction";
import {ThunkAction} from "redux-thunk";
import {Action} from "redux";
import {toast} from "react-toastify";
import {ThunkDispatcher} from "model/store/thunk";
import {ArticleItem} from "types/entities/ArticleItem";
import {ShopArticleItemsVouchersRequest} from "types/entities/ShopArticleItemsVouchersRequest";
import {ShopArticleItemsVouchersResponse} from "types/entities/ShopArticleItemsVouchersResponse";

export const fetchVoucherAndApply = (dispatch: ThunkDispatcher<any>, data: ShopArticleItemsVouchersRequest, appliedCallback) => {
    dispatch<ThunkAction<any, any, any, Action<VoucherAction>>>(VoucherAction.fetchApplyShoppingCartVoucher(data))
        .then((r: ShopArticleItemsVouchersResponse) => {
            if (r.voucher) {
                dispatch(VoucherAction.setVoucherItem(r.voucher));
                dispatch(VoucherAction.applyVoucherItem(r.voucher));
                appliedCallback(false);
            }

            if (r.applyVouchersRequest.shopArticleItemCart) {
                dispatch(ShopAction.setShopArticleItemsCart(r.applyVouchersRequest.shopArticleItemCart));
            }

            return r;
        })
        .catch(err => {
            toast.error(err);
        });
};

export const findAppliedVoucher = (code: string, articleItems: ArticleItem[]) => {
    const articleItemFound = articleItems.find(articleItem => articleItem.appliedVoucher?.voucher.code === code);
    return articleItemFound;
};

export const fetchDeleteVoucherAndApply = (dispatch: ThunkDispatcher<any>, data: ShopArticleItemsVouchersRequest): Promise<VoucherResponse> => {
    return dispatch<ThunkAction<any, any, any, Action<VoucherAction>>>(VoucherAction.fetchDeleteShoppingCartVoucher(data)).then(r => {
      if (r.shopArticleItemsCart) {
        dispatch(ShopAction.setShopArticleItemsCart(r.shopArticleItemsCart));
      }
        return r;
    });
};
