import React from "react";
import clsx from "clsx";

interface JumboTextProps {
    text: string | number;
    className?: string;
}

export const JumboText = (props: JumboTextProps) => {
    return <h1 className={clsx("font-k text-grey-800 text-9xl 2xl:text-13xl px-5 font-medium", props.className)}>{props.text}</h1>;
};
