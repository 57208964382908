import React, {useEffect, useState} from "react";
import {closeModal} from "helpers/actions";
import {useDispatch} from "useDispatch";
import SuspenseLoader from "components/SuspenseLoader";
import {useTypedSelector} from "helpers/reducers";
import "./ResultPopup.css";
import ResultPopupHeader from "./ResultPopup/ResultPopupHeader";
import SessionAction from "store/session/SessionAction";
import RaceResultDrivers from "pages/Front/components/ResultPopup/RaceResultDrivers";
import RaceResultLaps from "pages/Front/components/ResultPopup/RaceResultLaps";
import {consoleLog} from "utils/utility";
import { useTranslation } from "react-i18next";

const ResultPopup = () => {
    const popup = useTypedSelector(state => state.modal);
    const {t} = useTranslation();

    const resultData = useTypedSelector(state => state.sessionState.result);
    const [isOpen, setIsOpen] = useState(popup.component === "ResultPopup");
    const [isLoading, setIsLoading] = useState(false);
    //const popup = null;
    const dispatch = useDispatch();
    const openPopup = id => {
        setIsOpen(true);
        if(!isLoading) {
            setIsLoading(true);
            dispatch(SessionAction.fetchResult(id)).then(r => {
                consoleLog(r);
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false);
            });
        }
    };

    const closePopup = () => {
        dispatch(closeModal());
        setIsOpen(false);
    };

    const popupChanged = popup => {
        if (popup.component === "ResultPopup" && popup.isOpen) {
            openPopup(popup.props.id);
        } else {
            // closePopup();
        }
    };

    useEffect(() => {
        popupChanged(popup);
    }, [popup]);
    useEffect(() => {
        popupChanged(popup);

        return () => {};
    }, []);

    if (!isOpen) {
        return null;
    }
    if (!resultData) {
        return <SuspenseLoader />;
    }
    const res = resultData.forTemplate;

    if (!res) {
        return <SuspenseLoader />;
    }


    if (!res.result.raceResultDrivers) {
        return <SuspenseLoader />;
    }

    return (
        <div
            className="ResultPopup absolute inset-0 z-50 bg-white"
            style={{
                display: isOpen ? "block" : "none",
                opacity: 1,
                transform: "translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
            }}
        >
            <div className="ClosePopup absolute left-4 top-4 bottom-auto right-auto z-2 cursor-pointer" onClick={closePopup}>
                <div className="relative flex z-1 w-7 h-7 m-0 justify-center items-center rounded-full bg-transparent shadow-lg">
                    <div className="w-full h-full p-1.5">
                        <img alt={t("Close popup")} height="20" src="/img/close.svg" width="20" />
                    </div>
                </div>
            </div>
            <div className="RaceResults relative p-4 lg:p-10 2xl:p-20 flex-col flex w-full h-full " id="races">
                <ResultPopupHeader resultData={resultData} />
                <div className="RaceResultTable relative flex flex-col w-full h-full overflow-y-auto overflow-x-auto">
                    <div className={"absolute left-0 top-0"}>
                    <RaceResultDrivers res={res} />
                    <RaceResultLaps res={res} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResultPopup;
