import CalendarIcon from "@heroicons/react/24/solid/CalendarIcon";
import clsx from "clsx";
import React from "react";
import {useTranslation} from "react-i18next";
import {VoucherItemValidity} from "types/entities/specific/VoucherItemValidity";
import Dates from "utils/Dates";

interface VoucherItemDateValidityProps {
    voucherItemValidity: VoucherItemValidity;
}

const VoucherItemDateValidity = (props: VoucherItemDateValidityProps) => {
    const {t} = useTranslation();
    return (
        <dt className={"DateValidity flex justify-end"}>
            {!props.voucherItemValidity.includeDateRange &&
                props.voucherItemValidity.includeDateRanges &&
                props.voucherItemValidity.includeDateRanges[0] &&
                props.voucherItemValidity.includeDateRanges[0].from && (
                    <div className={"text-2xs max-w-[70px] leading-3"}>
                        {t("Valid from")}
                        <br />
                        <em>{Dates.ymd(props.voucherItemValidity.includeDateRanges[0].from, "DD MMM YYYY")}</em>
                    </div>
                )}
            {props.voucherItemValidity.limited && props.voucherItemValidity.includeWeekDays.length > 0 && (
                <div
                    className={"getValidityByWeekDayObjectLimited flex flex-row items-center justify-center"}
                    title={
                        t("Valid on days") +
                        ":  " +
                        t(props.voucherItemValidity.includeWeekDays[0]) +
                        " - " +
                        t(props.voucherItemValidity.includeWeekDays[props.voucherItemValidity.includeWeekDays.length - 1])
                    }
                >
                    <CalendarIcon className={clsx(props.voucherItemValidity.validity ? " text-green-600" : "text-red-500", "w-5 h-5 mr-2")} />
                        <div className={"text-xs font-medium"}>
                            <span className={"tracking-tight"}>
                                {t(props.voucherItemValidity.includeWeekDays[0].substring(0, 2))}
                                {" - "}
                                {t(props.voucherItemValidity.includeWeekDays[props.voucherItemValidity.includeWeekDays.length - 1].substring(0, 2))}
                            </span>
                        </div>
                </div>
            )}
        </dt>
    );
};

export default VoucherItemDateValidity;
