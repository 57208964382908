import React from "react";

interface SliderOfferProps {
    primary: string;
    secondary: string;
    type: string;
}

const SliderOffer = (props: SliderOfferProps) => {
    return (
        <div className={"SliderOffer flex flex-row justify-between items-center no-underline select-none"}>
            <div className="mr-4 sm:ml-16">
                <img alt="" src={`/img/${props.type}.svg`} width="35" />
            </div>
            <div className="flex grow justify-center items-start flex-col mr-4 no-underline hover:no-underline">
                <div className="font-m leading-4 lg:leading-5 xl:leading-5 min-h-[2.5rem] text-md lg:text-xl tracking-tight xl:text-2xl flex items-center text-white font-bold italic uppercase ">
                    <span>{props.primary}</span>
                </div>
                <div className="text-gray-500 text-xs font-m font-light no-underline">{props.secondary}</div>
            </div>
        </div>
    );
};

export default SliderOffer;
