import {Link, withRouter} from "react-router-dom";
import React from "react";
import "assets/ExtraStyle.css";
import {useTypedSelector} from "helpers/reducers";
import {Hall} from "types/entities/Hall";
import Loader from "components/Loader";
import clsx from "clsx";
import SwitchHallDropdown from "pages/Front/components/SwitchHallDropdown";
import NotificationsDropdown from "pages/Front/components/NotificationsDropdown";
import HallLogo from "./HallLogo";
import {ShopArticlesCount} from "pages/Front/Shop/components/ShopArticlesCount";
import {RouteComponentProps} from "react-router";
import {HallCode} from "types/enums/HallCode";
import {useTranslation} from "react-i18next";
import {ShoppingCartIcon} from "@heroicons/react/24/outline";

interface HomeTrackProps {
    hall: Hall | undefined;
    isMobile: boolean;
}

interface Params {}

interface NavBarTopProps extends RouteComponentProps<Params> {
    isMobile: boolean;
    onShopClick: () => null;
}

const HomeTrack = ({hall}: HomeTrackProps) => {
    if (!hall) {
        return (
            <div className={"No Home Track"}>
                <Loader />
            </div>
        );
    }
    return (
        <div className={clsx("NavBarTop__center relative items-center justify-center flex flex-0 flex-col")}>
            <a className="relative inline-flex justify-start " href="/">
                <HallLogo hall={hall} />
            </a>
        </div>
    );
};
const NavBarTop = (props: NavBarTopProps) => {
    const profile = useTypedSelector(state => state.sessionState.profile);
    const shopArticles = useTypedSelector(state => state.shopState.itemsCart.shopArticleItems);
    const hall = useTypedSelector(state => state.hallState.hall);
    const {t} = useTranslation();

    const profileHall = profile?.hall;
    const location = props.location;
    const handleShop = () => {
        if (location.pathname.includes("/shop")) {
            props.onShopClick();
        }
    };
    return (
        <div className="NavBarTop fixed inset-x-0 top-0 bottom-auto h-12 lg:h-16 2xl:h-20 flex-col items-center flex z-50 bg-white">
            <div className={clsx(!props.isMobile ? "pl-16 2xl:pl-20 " : "" ,"NavBarTop__inner flex relative px-3 z-10 w-full  h-full justify-between lg:justify-center place-content-between")}>
                {/*{!props.isMobile && (*/}
                {/*    <div className="NavBarTop__left absolute h-full flex justify-between lg:min-w-[200px] left-0 right-auto inset-y-0">*/}
                {/*        <DashboardQueuesTimeDropdown />*/}
                {/*    </div>*/}
                {/*)}*/}
                <HomeTrack hall={profileHall} isMobile={props.isMobile} />

                <div className="NavBarTop__right lg:absolute h-full left-auto right-4 bottom-0 top-0 flex divide-x divide-gray-600 lg:min-w-[200px] justify-between">
                    <NotificationsDropdown />
                    {hall && hall.hall_code !== HallCode.Zwolle && (
                        //temporary turn of this feature
                        <SwitchHallDropdown />
                    )}
                    <Link className="w-full h-full p-2 flex justify-center items-center" title={t("Profile")} to="/profile">
                        <div className="w-6 h-6 text-dark-900 my-2">
                            <svg
                                className="w-full h-full Profile"
                                fill="none"
                                height="18"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.309 3l.186 1a2.0003 2.0003 0 002.1786 1.6334A1.9996 1.9996 0 0016.6 5.29l.842-.58 1.848 1.852-.58.842A2.0002 2.0002 0 0020 10.505l1 .186v2.618l-1 .191a1.9998 1.9998 0 00-1.285 3.1l.58.842-1.857 1.848-.842-.58A2 2 0 0013.5 20l-.186 1h-2.623l-.186-1a2.0005 2.0005 0 00-1.2008-1.4915A2.0005 2.0005 0 007.4 18.71l-.842.58-1.848-1.852.58-.842a1.9999 1.9999 0 00-1.285-3.1l-1-.186v-2.619l1-.186a1.9992 1.9992 0 001.488-1.2026A2 2 0 005.29 7.4l-.58-.842L6.562 4.71l.842.58a2.0001 2.0001 0 003.1-1.285l.186-1L13.309 3z"
                                    strokeMiterlimit="10"
                                />
                                <circle cx="12" cy="12" r="3" />
                            </svg>
                        </div>
                    </Link>

                    <Link className="w-full h-full p-2 flex justify-center items-center" title={t("Shop")} to="/shop" onClick={() => handleShop()}>
                        <ShopArticlesCount count={shopArticles.length} />

                        <div className="w-6 h-6 my-2 select-none pointer-events-none">
                            <ShoppingCartIcon className={"w-6 h-6 text-dark-900"} />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default withRouter(NavBarTop);
