export const MANIPULATE_SHOP_ITEM = "MANIPULATE_SHOP_ITEM";
export const SET_IP = "SET_IP";
export const SET_RESULT = "SET_RESULT";
export const SET_HALL = "SET_HALL";
export const SET_RESULTS = "SET_RESULTS";
export const CREATE_RESERVATION = "CREATE_RESERVATION";
export const CREATE_RESERVATION_DRIVER = "CREATE_RESERVATION_DRIVER";
export const CONFIRM_RESERVATION = "CONFIRM_RESERVATION";
export const SET_RESERVATION_SLOTS = "SET_RESERVATION_SLOTS";
export const SET_RESERVATION_RESULT = "SET_RESERVATION_RESULT";
export const SET_RESERVATION_DRIVER_RACES = "SET_RESERVATION_DRIVER_RACES";
export const SELECT_RESERVATION_SLOT = "SELECT_RESERVATION_SLOT";
export const DELETE_RESERVATION_DRIVER = "DELETE_RESERVATION_DRIVER";
export const TOGGLE_SELECT_RESERVATION_DRIVER = "TOGGLE_SELECT_RESERVATION_DRIVER";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_ERROR = "SET_ERROR";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_PAYMENT = "SET_PAYMENT";
export const SET_DARK_MODE = "SET_DARK_MODE";
export const SET_TICKETS = "SET_TICKETS";
export const AJAX_LOADING = "AJAX_LOADING";
export const SET_SESSION = "SET_SESSION";
export const SET_TIMESLOTS = "SET_TIMESLOTS";
export const SET_QUEUES = "SET_QUEUES";
export const SET_HALLS = "SET_HALLS";
export const SET_ARTICLES = "SET_ARTICLES";
export const SET_PURCHASE_HISTORY = "SET_PURCHASE_HISTORY";
export const SET_PROFILE = "SET_PROFILE";
export const SET_BLUR = "SET_BLUR";
export const SET_ME = "SET_ME";
export const SET_RANKING = "SET_RANKING";
export const SET_RESERVATIONS = "SET_RESERVATIONS";
export const SET_MY_STATS = "SET_MY_STATS";
export const SET_DEBUG = "SET_DEBUG";
export const OPEN_MODAL = "OPEN_MODAL";
