import T from "components/T";
import React from "react";

interface CardTagProps {
    label: string;
}
const CardTag = (props: CardTagProps) => {
    return (
        <div className="CardTag flex justify-between items-center opacity-50 text-gray-800 self-start">
            <h1 className="font-i text-base font-light">
                <T>{props.label}</T>
            </h1>
        </div>
    );
};

export default CardTag;
