import HallAction from "store/hall/HallAction";
import {Hall} from "types/entities/Hall";
import {Language} from "types/entities/Language";
import update from "immutability-helper";
import {ReducerResetAction} from "store/session/SessionReducer";
import SessionAction from "store/session/SessionAction";

export interface ReducerSetHallAction {
    payload: Hall,
    type: typeof HallAction.SET_HALL
}

export interface ReducerSetHallsListAction {
    payload: Hall[],
    type: typeof HallAction.SET_HALLS_LIST
}

export interface ReducerSetLanguageAction {
    payload: Language,
    type: typeof HallAction.SET_LANGUAGE
}


type ReducerHallAction = ReducerSetHallAction
    | ReducerSetHallsListAction
    | ReducerSetLanguageAction
    | ReducerResetAction;


interface ReducerHallState {
    hall: Hall | null,
    hallsList: Hall[] | null,
}

class HallReducer {
    initialState: ReducerHallState = {
        hall: null,
        hallsList: null
    }

    reducer = (state: ReducerHallState = this.initialState, action: ReducerHallAction): ReducerHallState => {
        switch (action.type) {
            case HallAction.SET_HALL:
                return update(state, {
                    hall: {
                        $set: action.payload
                    }
                })
            case HallAction.SET_HALLS_LIST:
                return update(state, {
                    hallsList: {
                        $set: action.payload
                    }
                })
            case HallAction.SET_LANGUAGE:
                return update(state, {
                    hall: {
                        language: {
                            $set: action.payload
                        }
                    }
                })
            case SessionAction.RESET:
                return this.initialState;

            default:
                return state;
        }
    }
}

export default HallReducer;