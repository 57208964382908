import {BookingTimeslot} from "types/app";
import {createAction} from "helpers/createAction";
import {ThunkAction} from "model/store/thunk";
import {BookingActivitiesResponse, BookingPackagesResponse, BookingPackagesTimeslotsResponse} from "types/responses";
import ajax from "helpers/ajax";
import {BookingPackagesInput, PackagesTimeSlots} from "store/booking/BookingAction";
import {DEFAULT_HALL_ID} from "helpers/config";
import {Package} from "types/entities/Package";
import {BookingInitCartResponse} from "types/responses/BookingInitCartResponse";
import {PackagesCart} from "types/entities/PackagesCart";
import {Activity} from "types/entities/Activity";
import {Reservation} from "types/entities/Reservation";
import {Paginator} from "pages/Front/ReservationHistory/ReservationHistory";
import {ReservationSlotDriverEntity} from "types/entities/ReservationSlotDriverEntity";
import {Action} from "redux";
import { ReservationDriver } from "types/entities/ReservationDriver";

class ReservationAction {
    static readonly SET_RESERVATION_DRIVER = "ReservationAction.SetReservationDriver";
    static readonly SET_RESERVATION_DRIVER_RACES = "ReservationAction.SetReservationDriverRaces";
    static readonly SET_TOGGLE_SELECT_RESERVATION_DRIVER = "ReservationAction.SetToggleSelectReservationDriver";
    static readonly INCREMENT_RESERVATION_DRIVER_RACES_COUNT = "ReservationAction.CreateReservationDriver";
    static readonly DELETE_RESERVATION_DRIVER = "ReservationAction.DeleteReservationDriver";
    static readonly SET_RESERVATION_DRIVER_ROW = "ReservationAction.SetReservationDriverRow";
    static readonly SET_TIMESLOTS = "ReservationAction.SetTimeslots";
    static readonly TOGGLE_SELECTED_TIMESLOT = "ReservationAction.ToggleSelectedTimeslot";
    static readonly SET_RESERVATION_FILTER = "ReservationAction.SetReservationFilter";
    static readonly SET_PACKAGES = "ReservationAction.SetPackages";
    static readonly SET_PACKAGES_CART = "ReservationAction.SetPackageCart";
    static readonly SET_ACTIVITIES = "ReservationAction.SetActivities";
    static readonly SET_RESERVATIONS_LIST = "ReservationAction.SetReservationsList";
    static readonly SET_ACTIVE_RESERVATIONS_LIST = "ReservationAction.SetActiveReservationsList";
    static readonly SET_SELECTED_TIMESLOT = "ReservationAction.SetActiveReservationsList";
    static readonly SET_RESERVATION_RESULT = "ReservationAction.SetReservationResult";
    static readonly SET_RESERVATION = "ReservationAction.SetReservation";
    static readonly RESET_RESERVATION = "ReservationAction.ResetReservation";

    static readonly SET_CURRENT_NAVIGATION_STEP = "ReservationAction.SetCurrentNavigationStep";

    static fetchActivities = (): ThunkAction<Promise<BookingActivitiesResponse>> => {
        return dispatch => {
            return ajax
                .get("/booking/activities", {
                    params: {
                        hall_id: DEFAULT_HALL_ID,
                    },
                })
                .then((r: BookingActivitiesResponse) => {
                    dispatch<Action<ReservationAction>>(ReservationAction.setActivities(r.activities));
                    return r;
                });
        };
    };

    static fetchPackagesTimeSlots = (data: PackagesTimeSlots): ThunkAction<Promise<BookingPackagesTimeslotsResponse>> => {
        return dispatch => {
            return ajax.get("/booking/timeslots", {params: data}).then((r: {bookingTimeslots: BookingTimeslot[]}) => {
                //console.log(r);
                dispatch<Action<ReservationAction>>(ReservationAction.setTimeslots(r.bookingTimeslots));
                return r;
            });
        };
    };

    static fetchPackages = (data: BookingPackagesInput): ThunkAction<Promise<BookingPackagesResponse>> => {
        return dispatch => {
            return ajax
                .get("/booking/packages", {
                    params: {
                        hall_id: DEFAULT_HALL_ID,
                        ...data,
                    },
                })
                .then((r: BookingPackagesResponse) => {
                    const _package = r.packages?.find(pck => pck.category === "profile") || null;

                    dispatch<Action<ReservationAction>>(ReservationAction.setPackages(_package));
                    // dispatch(BookingAction.setTimeslotsAllowed(r.timeslotsAllowed));
                    return r;
                });
        };
    };

    static initCart = (): ThunkAction<Promise<BookingInitCartResponse>> => {
        return (dispatch, getState) => {
            const session = getState().sessionState.session;
            return ajax.post("/booking/cart/create", {hall_id: DEFAULT_HALL_ID, driver_id: session.uid}, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                },
            }).then(r => {
                dispatch<Action<ReservationAction>>(ReservationAction.setPackagesCart(r.packageCart));
                dispatch<Action<ReservationAction>>(ReservationAction.setReservation(r.packageCart.reservation));
                return r;
            });
        };
    };

    static confirmReservation = (cart_id: number, cart_token: string, user_id: number): ThunkAction<Promise<Response>> => {
        return (dispatch, getState) => {
            const session = getState().sessionState.session;

            return ajax.post(`/booking/cart/${cart_id}/${cart_token}/finish-profile-reservation`, {user_id: user_id}, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                },
            }).then(r => {
                dispatch<Action<ReservationAction>>(ReservationAction.setReservationResult(r.packageCart.reservation));
                return r;
            });
        };
    };

    static createOrAssignDriversToExistingReservation = (reservation_id: number, users: ReservationSlotDriverEntity[]) => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;
            return ajax.post("/reservations/create-users", {reservation_id, users}, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                },
            }).then(r => {
                return r;
            });
        };
    };

    static resetReservation = (reservation) => {
        return createAction(ReservationAction.RESET_RESERVATION, reservation);
    };

    static fetchReservations = (filterTime: string, page: number = 1, perPage: number = 5): ThunkAction<Promise<Response>, any> => {
        return (dispatch, getState): Promise<any> => {
            const session = getState().sessionState.session;

            return ajax
                .get("/reservations/list", {
                    params: {
                        time_filter: filterTime,
                        limit: perPage,
                        page: page,
                        status_filters: ["reserved", "pending", "cancelled", "deleted"],
                    },
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${session.access_token}`,
                    },
                })
                .then(r => {
                    if(filterTime === "current") {
                        dispatch<Action<ReservationAction>>(ReservationAction.setActiveReservationsList(r.reservations.data));
                    }
                    dispatch<Action<ReservationAction>>(ReservationAction.setReservationsList(r.reservations));
                    return r;
                });
        };
    };

    static fetchReservationsByURL = (url: string, session): (dispatch) => any => {
        return dispatch => {
            return ajax.get(url, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                },
            }).then(r => {
                dispatch(ReservationAction.setReservationsList(r.reservations));
                return r;
            });
        };
    };

    static deleteReservationDriver = (driver: ReservationDriver) => {
        return createAction(ReservationAction.DELETE_RESERVATION_DRIVER, driver);
    };

    static toggleSelectReservationDriver = (driver: ReservationDriver) => {
        return createAction(ReservationAction.SET_TOGGLE_SELECT_RESERVATION_DRIVER, driver);
    };

    static incrementReservationDriverRacesCount = (driver: ReservationDriver, increment: number) => {
        return createAction(ReservationAction.SET_RESERVATION_DRIVER_RACES, {driver: driver, increment: increment});
    };

    static createReservationDriver = (newDriver: ReservationDriver) => {
        return createAction(ReservationAction.SET_RESERVATION_DRIVER, newDriver);
    };

    static setCurrentNavigationStep = (data: {id: number; name: string}) => {
        return createAction(ReservationAction.SET_CURRENT_NAVIGATION_STEP, data);
    };

    static setTimeslots = (timeslots: BookingTimeslot[]) => {
        return createAction(ReservationAction.SET_TIMESLOTS, timeslots);
    };
    static toggleSelectedTimeslot = (timeslot: BookingTimeslot) => {
        return createAction(ReservationAction.TOGGLE_SELECTED_TIMESLOT, timeslot);
    };

    static setReservationDriverRow = (data: {driver: ReservationDriver; identifier: number}) => {
        return createAction(ReservationAction.SET_RESERVATION_DRIVER_ROW, data);
    };

    static setReservationFilter = (filter: PackagesTimeSlots) => {
        return createAction(ReservationAction.SET_RESERVATION_FILTER, filter);
    };

    static setPackages = (_package: Package) => {
        return createAction(ReservationAction.SET_PACKAGES, _package);
    };
    static setPackagesCart = (packageCart: PackagesCart) => {
        return createAction(ReservationAction.SET_PACKAGES_CART, packageCart);
    };

    static setActivities = (activities: Activity[]) => {
        return createAction(ReservationAction.SET_ACTIVITIES, activities);
    };

    static setReservationsList = (reservations: Paginator<Reservation>) => {
        return createAction(ReservationAction.SET_RESERVATIONS_LIST, reservations);
    };

    static setActiveReservationsList = (reservations: Paginator<Reservation>) => {
        return createAction(ReservationAction.SET_ACTIVE_RESERVATIONS_LIST, reservations);
    };

    static setReservationResult = (reservationResult: Reservation) => {
        return createAction(ReservationAction.SET_RESERVATION_RESULT, reservationResult);
    };

    static setReservation = (reservation: Reservation) => {
        return createAction(ReservationAction.SET_RESERVATION, reservation);
    };
}

export default ReservationAction;
