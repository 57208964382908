import React from "react";
import clsx from "clsx";

interface MutedTextProps {
    text: string;
    className?: string;
}

export const MutedText = (props: MutedTextProps) => {
    return <div className={clsx("font-i text-opacity-50 text-inherit font-normal text-2xs lg:text-sm 2xl:text-base", props.className)}>{props.text}</div>;
};
