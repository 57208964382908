import {addFlashMessage, setAjaxLoading, setDebug, setError, setSuccess} from "helpers/actions";
import React from "react";
import {ROOT_API_WEB} from "helpers/config";
import {IS_DEV} from "../config";

const AxiosCommon = {
    applyRequestInterceptor(instance, setError, store) {
        instance.interceptors.request.use(
            config => {
                /** In dev, intercepts request and logs it into console for dev */
                if (typeof config.loader === "undefined" || config.loader === true) {
                    store.dispatch(setAjaxLoading(1, config.url));
                }

                const session = store.getState().session;
                if (session && session.access_token) {
                    config.headers["Authorization"] = "Bearer " + session.access_token;
                }

                return config;
            },
            error => {
                store.dispatch(setAjaxLoading(-1));

                return Promise.reject(error);
            }
        );
    },
    applyResponseInterceptor: (instance, showMessageFunction, store) => {
        // Add a response interceptor
        instance.interceptors.response.use(
            function (response) {
                //console.log(response.config);
                const camel = response.data; //response.config.noCamelCase ? response.data : AxiosCommon.toCamelCase(response.data);

                if (typeof response.config.loader === "undefined" || response.config.loader === true) {
                    if (response.request.fromCache) {
                        store.dispatch(setAjaxLoading(-1));
                    } else {
                        store.dispatch(setAjaxLoading(-1, response.request.responseURL.replace(ROOT_API_WEB, "")));
                    }
                }

                if (camel.redirect) {
                    window.location.href = camel.redirect;
                    return false;
                }

                if (response && typeof response.data === "string" && response.data.indexOf("<") === 0) {
                    store.dispatch(setDebug(response.data));
                    return false;
                }

                if (typeof camel.success === "undefined") {
                    store.dispatch(setError("Missing server reply. Probably a bug."));
                    return Promise.reject(response);
                }

                if (camel.success === false) {
                    store.dispatch(setError(camel.message || "General error"));
                    return Promise.reject(response);
                } else if (camel.success === true) {
                    if (!camel.message || camel.message === "OK") {
                        if (response.config.method !== "get") {
                            // store.dispatch(setSuccess(<div>Operation succeeded.</div>));
                        }
                    } else {
                        store.dispatch(setSuccess(<div>{camel.message}</div>));
                    }
                }

                if (camel.flashMessages) {
                    camel.flashMessages.map(flash => store.dispatch(addFlashMessage(<div>{flash.message}</div>, flash.type)));
                }

                if (!camel.data) {
                    store.dispatch(setError("Missing server reply. Probably a bug."));
                    return Promise.reject(response);
                }

                return camel.data;
            },
            function (error) {
                store.dispatch(setAjaxLoading(-1));

                if (error.response && error.response.data) {
                    if (error.response.status === 403) {
                        console.log("error 403");
                    } else if (error.response.status === 401) {
                        console.log("error 401");
                        return Promise.reject(error.response);
                        //window.location.href = "/";
                    }
                }

                if (error.response) {
                    console.debug(error.response);
                    if (typeof error.response.data === "string" && error.response.data.indexOf("<") === 0) {
                        store.dispatch(setDebug(error.response.data));
                        return Promise.reject(error.response);
                    } else {
                        //store.dispatch(setDebug(error.response));
                    }

                    if (error.response.data.errors) {
                        store.dispatch(setDebug(error.response.data));

                        store.dispatch(
                            showMessageFunction(
                                <div className={"message"}>
                                    {IS_DEV && (
                                        <span>
                                            Error {error.response.status === 400 && ""+error.response.status}:<br />
                                        </span>
                                    )}
                                    {Object.values(error.response.data.errors).map(errors => errors)}
                                </div>
                            )
                        );
                    } else if (error.response.data.message) {
                        const message = [];
                        const messagesInData = error.response.data?.data?.messages;
                        if (error.response.data.code === 113) {
                            store.dispatch(setDebug(error.response.data));
                        } else if (error.response.data.code === 115 && messagesInData) {
                            Object.keys(messagesInData).map(key => {
                                messagesInData[key].map(item => {
                                    message.push(<div>{item}</div>);
                                });
                            });
                        }
                        store.dispatch(
                            showMessageFunction(
                                <div className={"errorMessage"}>
                                    {IS_DEV && (
                                        <div>
                                            Error {error.response.status}: {error.response.config.url}
                                        </div>
                                    )}
                                    {message.length > 0 ? message : error.response.data.message || error.response.message || error.message}
                                </div>
                            )
                        );
                    }
                } else {
                    store.dispatch(showMessageFunction(<div>Are you connected to the internet? CORS?</div>));
                }

                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                return Promise.reject(error.response);
            }
        );
    },
};
export default AxiosCommon;
