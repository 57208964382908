import {openModal as openModalAction, setDebug, setMessage} from "helpers/actions";
import store from "helpers/store";
import React, {Component} from "react";
import {connect} from "react-redux";
import Modal from "react-modal";
import Styles from "helpers/Styles";
import Dump from "components/Dump";
import {toast} from "react-toastify";

Modal.setAppElement("#root");

const DebugModal = props => {
    var [modalIsOpen, setIsOpen] = React.useState(true);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
        store.dispatch(setDebug(null));
        store.dispatch(openModalAction(null, null));
    }
    return (
        <div>
            <Modal contentLabel={"Label"} isOpen={modalIsOpen} style={Styles.modal} onAfterOpen={afterOpenModal} onRequestClose={closeModal}>
                {typeof props.children === "string" && <div dangerouslySetInnerHTML={{__html: props.children}} />}
                {typeof props.children !== "string" && props.children}
            </Modal>
        </div>
    );
};

class AlertResponder extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.message) {
            if (this.props.message.message === "") {
                return;
            }
            if (this.props.message.messageType === "error") {
                toast.error(this.props.message.message);
                //this.props.alert.error(this.props.message.message);
            } else {
                toast.success(this.props.message.message);
                //this.props.alert.success(this.props.message.message);
            }

            store.dispatch(setMessage("", ""));
        }

        if (this.props.debug) {
        }
    }

    render() {
        /*  if (this.props.modal && this.props.modal.component) {
            const ModalComponent = this.props.modal.component;
            return (
                <DebugModal>
                    <ModalComponent {...this.props.modal.props} />
                </DebugModal>
            );
        }
*/
        if (typeof this.props.debug === "string") {
            return <DebugModal>{this.props.debug}</DebugModal>;
        } else if (this.props.debug !== null) {
            return (
                <DebugModal>
                    <Dump data={this.props.debug} />
                </DebugModal>
            );
        }
        return null;
    }
}
const mapStateToProps = state => ({
    message: state.message,
    debug: state.debug,
    modal: state.modal,
});
export default connect(mapStateToProps)(AlertResponder);
