import update from "immutability-helper";
import {Payment} from "types/entities/Payment";
import PurchaseHistoryAction from "./PurchaseHistoryAction";

export interface PurchaseHistoryReducerPushPurchaseHistoryAction {
    payload: Payment[];
    type: typeof PurchaseHistoryAction.PUSH_PURCHASE_HISTORY;
}
export interface PurchaseHistoryReducerSetPurchaseHistoryAction {
    payload: Payment[];
    type: typeof PurchaseHistoryAction.SET_PURCHASE_HISTORY;
}
export interface PurchaseHistoryReducerClearPurchaseHistoryAction {
    payload: [];
    type: typeof PurchaseHistoryAction.CLEAR_PURCHASE_HISTORY;
}

export interface PurchaseHistoryState {
    purchaseHistory: Payment[];
}

type PurchaseHistoryReducerAction =
    PurchaseHistoryReducerPushPurchaseHistoryAction
    | PurchaseHistoryReducerSetPurchaseHistoryAction
    | PurchaseHistoryReducerClearPurchaseHistoryAction;

class PurchaseHistoryReducer {
    initialState: PurchaseHistoryState = {
        purchaseHistory: [],
    };

    reducer = (state: PurchaseHistoryState = this.initialState, action: PurchaseHistoryReducerAction): PurchaseHistoryState => {
        switch (action.type) {
            case PurchaseHistoryAction.PUSH_PURCHASE_HISTORY:
                const idFilter = action.payload.map(item => item.id);
                const purchaseHistoryFilter = state.purchaseHistory.filter(referral => !idFilter.includes(referral.id));
                const purchaseHistory = [...new Set([...purchaseHistoryFilter, ...action.payload])];

                return update(state, {
                    purchaseHistory: {
                        $set: purchaseHistory,
                    },
                });

            case PurchaseHistoryAction.SET_PURCHASE_HISTORY:
                return update(state, {
                    purchaseHistory: {
                        $set: action.payload,
                    },
                });

            case PurchaseHistoryAction.CLEAR_PURCHASE_HISTORY:
                return update(state, {
                    purchaseHistory: {
                        $set: [],
                    },
                });

            default:
                return state;
        }
    };
}

export default PurchaseHistoryReducer;