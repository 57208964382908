import Dates from "utils/Dates";
import T from "components/T";
import React, {useState} from "react";
import {Driver} from "types/entities/Driver";
import {RaceResultData} from "types/entities/RaceResultData";
import {useTranslation} from "react-i18next";
import {useDispatch} from "useDispatch";
import {useTypedSelector} from "helpers/reducers";
import SessionAction from "store/session/SessionAction";

interface ResultPopupHeaderProps {
    resultData?: RaceResultData;
    driverStats?: Driver;
}

interface ResultArrowProps {
    way: string;
    onClick: () => void;
}

const ResultArrow = (props: ResultArrowProps) => {
    const {t} = useTranslation();
    return (
        <div
            className="ResultArrow relative z-1 flex overflow-visible lg:w-10 h-10 m-0 justify-center items-center rounded-full bg-transparent shadow-none cursor-pointer"
            onClick={props.onClick}
        >
            {props.way === "prev" ? (
                <img alt={t("Load previous result")} className="w-full h-full object-fill" src="/img/arrowLeft.svg" />
            ) : (
                <img alt={t("Load next result")} className="w-full h-full object-fill" src="/img/arrowRight.svg" />
            )}
        </div>
    );
};

const ResultPopupHeaderLabel = (props: ResultPopupHeaderProps) => {
    return (
        <div className="ResultPopupHeaderLabel relative mr-4 font-sans-serif-g">
            {props.resultData && props.resultData.forTemplate && props.resultData.forTemplate.result && (
                <div
                    className="text-red-f0 leading-4 text-lg font-light">{Dates.date(props.resultData.forTemplate.result.timestamp_added)}</div>
            )}
            {props.resultData && props.resultData.forTemplate && props.resultData.forTemplate.result && props.resultData.forTemplate.result.race && (
                <div className="text-2xl leading-6 font-medium">
                    <T>Race</T> <span className="text-xl">{props.resultData.forTemplate.result.race.race_number}</span>
                </div>
            )}
            <div className="text-gray-9c text-sm font-normal leading-5">
                <T>Race stats</T>
            </div>
        </div>
    );
};

interface InfoBoxProps {
    label: string;
    value: string;
}

const ResultPopupHeader = (props: ResultPopupHeaderProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const results = useTypedSelector(state => state.sessionState.results);

    const [newResArr, setNewResArr] = useState([...results]);
    const [index, setIndex] = useState(results.findIndex(result => result.race_result_id === props.resultData?.forTemplate.result.id));
    const session = useTypedSelector(state => state.sessionState.session);
    const loggedUserId = session?.uid;

    const driverStats = props.resultData?.forTemplate.detailStats.filter(detailStat => detailStat.driver_id === loggedUserId);


    const getResultDetail = (guid: string) => {
        dispatch(SessionAction.fetchResult(guid));
    };

    const getPrevResultDetail = () => {
        if (index - 1 >= 0) {
            getResultDetail(newResArr[index - 1].race_guid);
            setIndex(index - 1);
        }
    };

    const getNextResultDetail = () => {
        if (index + 1 < newResArr.length) {
            getResultDetail(newResArr[index + 1].race_guid);
            setIndex(index + 1);
        }
    };
    const ResultHeaderInfoBox = (props: InfoBoxProps) => {
        return (
            <div className="ResultHeaderInfoBox p-2">
                <div className="font-sans-serif-g leading-5 text-lg font-normal">{props.label}</div>
                <div className="text-gray-9c text-sm font-normal leading-5">{props.value}</div>
            </div>
        );
    };
    return (
        <header className="RaceResultHeader flex w-full flex-col items-stretch font-light justify-start h-40">
            <div className="flex w-full flex-col justify-between sm:justify-start">
                <div
                    className="RaceResultNavigation flex justify-center w-full sm:w-auto flex-grow sm:flex-grow-0 align-baseline sm:justify-start gap-x-8 items-center">
                    <ResultArrow way={"prev"} onClick={getPrevResultDetail} />
                    <ResultPopupHeaderLabel resultData={props.resultData} />
                    <ResultArrow way={"next"} onClick={getNextResultDetail} />
                </div>
                <div className="RaceResultStats flex justify-start items-start sm:px-4 mt-2">
                    {props.resultData && props.resultData.personalResult && (
                        <div className="grid lg:mt-0 rows-auto table-grid grid-cols-[0.5fr,0.7fr,1fr,0.5fr] sm:grid-cols-4 grid-rows-1">
                            <ResultHeaderInfoBox label={"" + props.resultData.personalResult.raceResultDriver.position}
                                                 value={t("Position")} />
                            <ResultHeaderInfoBox
                                label={`${(props.resultData?.personalResult?.raceResultDriver.best_lap_time / 100).toFixed(2)}s`}
                                value={t("Best lap")}
                            />
                            {driverStats && (
                                <>
                                    <ResultHeaderInfoBox label={""+(driverStats?.[0]).totalLapsCount}
                                                         value={t("Total laps")} />
                                    <ResultHeaderInfoBox label={""+driverStats?.[0].lastKart} value={t("Kart")} />
                                </>
                            )}

                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default ResultPopupHeader;
