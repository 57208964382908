import React, {ReactNode, useEffect, useState} from "react";
import {useTypedSelector} from "helpers/reducers";
import {useDispatch} from "useDispatch";
import {toast} from "react-toastify";
import {ShopCartSummary} from "./ShopCartSummary";
import CardBackgroundStripes from "../../components/CardBackgroundStripes";
import {AdultOrChild} from "types/app";
import {SubmitButton} from "components/Buttons/Buttons";
import {useTranslation} from "react-i18next";
import {ShopCardItemRoundButton} from "./ShopCardItemRoundButton";
import {JumboText} from "../../components/TextStyles/JumboText";
import {getArticle, getArticlePrices} from "utils/utility";
import ShopAction from "store/shop/ShopAction";
import {ArticleType} from "types/enums/ArticleType";
import {Article} from "types/entities";
import {MAXIMUM_POINTS_COUNT, PAYMENT_TEST} from "helpers/config";
import {ExclamationTriangleIcon, MinusIcon, PlusIcon} from "@heroicons/react/24/solid";
import { fetchVoucherAndApply } from "utils/shoppingCartUtils";
import { ShopArticleItemsVouchersRequest } from "types/entities/ShopArticleItemsVouchersRequest";
import { useShopItemsCartHook } from "utils/giftCardShopUtils";

interface ShopCardProps {
    articleId?: number;
    active: boolean;
    articleType: ArticleType;
    title: string;
    subTitle: string | ReactNode;
    personType: AdultOrChild;
}

const ShopCardItem = (props: ShopCardProps) => {
    const {active, articleType, subTitle, title, personType} = props;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [count, setCount] = useState(1);
    const articlesState = useTypedSelector(state => state.articlesState);
    const voucherState = useTypedSelector(state => state.voucherState);
    const itemsCart  = useTypedSelector(state => state.shopState.itemsCart)
    const articlePrices = getArticlePrices(articlesState, articleType, personType, count);
    const article = getArticle(articlesState, articleType, personType, count);
    // if action ticket race_count_total = article.race_count_total
    const maximumTicketsCount = MAXIMUM_POINTS_COUNT;
    // TODO not yet refactored
    const [cardSummaryActive, setCardSummaryActive] = useState(false);
    const [applyPending, setApplyPending] = useState(false);
    const toastId = "ShopCardItem";

    const manipulateTicketCount = (increment: number) => {
        if (count + increment < 1) {
            setCount(1);
        } else if (count + increment > maximumTicketsCount) {
            toast.error(t("Maximum is ") + maximumTicketsCount, {
                toastId: toastId
            });
        } else {
            setCount(count + increment);
        }
    };

    const fetchVoucherAndApplyHandler = () => {
        const voucher = voucherState.appliedVoucherItems[0];
        const shopArticleItemsCart = itemsCart.shopArticleItemsCart;
        if (voucher && shopArticleItemsCart) {
            //
            const data: ShopArticleItemsVouchersRequest = {
                code: voucher.code,
                shop_article_items_cart_id: shopArticleItemsCart.id,
                hall_id: shopArticleItemsCart.hall_id,
            };
            fetchVoucherAndApply(dispatch, data, setApplyPending);
            return;
        }
    };
    const dispatchArticleItem = (article?: Article) => {
        const articleItem = {
            article_id: article.article_id,
            quantity: article.type === ArticleType.Online ? 1 : count, //
            personType: article.person_type,
            articleType: article.type,
        };
        //dispatch article Item by pushing
        //dispatch(ShopAction.pushArticleItem(articleItem));
        //articleItems.push(articleItem);
        // fetchVoucherAndApplyHandler(articleItems);
        if(itemsCart.shopArticleItemsCart) {
            dispatch(ShopAction.addShopArticleItem(articleItem, itemsCart.shopArticleItemsCart))
        }
    };

    const pushArticleItemToCart = (article: Article) => {
        if (PAYMENT_TEST) {
            dispatchArticleItem(3000);
            return;
        }
        dispatchArticleItem(article);
    };


    const confirmTicketCount = () => {
        //article is predefined by the quantity


        //article item for current personType is NOT found
        pushArticleItemToCart(article);
        toast.success(t("Ticket added!"), {
            toastId: toastId
        });
        setApplyPending(true);
        setCount(1);
    };

    const confirmTicketCountHandler = () => {
        confirmTicketCount();
    };

    useEffect(() => {
        if (applyPending) {
            fetchVoucherAndApplyHandler();
            setApplyPending(false);
        }
    }, []);

    return (
        <div
            className="ShopCardItem relative z-50 flex flex-col p-4 lg:p-6 2xl:p-8 max-w-xs sm:max-w-lg w-full"
            style={{
                display: active ? "block" : "none",
                opacity: active ? 1 : 0,
                transform: active ? "initial" : "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            }}
        >
            <div className="ShopCartItemTitle relative flex flex-col z-50 justify-center items-center select-none">
                <div className={"font-xs font-semibold tracking-wider font-i text-gray-800 uppercase"}>{t(title)}</div>
                <div
                    className="font-light font-i leading-4 text-xl sm:text-2xl tracking-tight text-gray-800 capitalize">{subTitle}</div>

            </div>
            <div className="ShopCartItemInner relative flex flex-col z-50 h-full items-center justify-center"
                 onMouseLeave={() => setCardSummaryActive(false)}>
                <div className="flex flex-col h-full items-stretch">
                    <div className="flex flex-row justify-center items-center z-50">
                        <ShopCardItemRoundButton symbol={<MinusIcon className={"w-6 h-6"} />}
                                                 onClick={() => manipulateTicketCount(-1)} />
                        <div
                            className="h-36 2xl:h-60 w-36 2xl:w-48 flex flex-col justify-center select-none items-center">
                            <JumboText text={count} />
                        </div>
                        <ShopCardItemRoundButton symbol={<PlusIcon className={"w-6 h-6"} />}
                                                 onClick={() => manipulateTicketCount(+1)} />
                    </div>
                    <div className={"flex flex-row items-center grow"}>
                        <ShopCartSummary active={cardSummaryActive} prices={articlePrices} />
                    </div>
                    {props.articleType === ArticleType.Action &&
                    <div className="font-light font-i leading-4 text-xs sm:text-base tracking-tight text-gray-700 flex flex-row items-center justify-center mb-1">
                        <ExclamationTriangleIcon className={"w-4 h-4 -ml-2 mr-2 text-blue-800"} /><span>{t("Ticket usable only on Monday and Tuesday")}</span></div>}
                    <SubmitButton
                        icon={<PlusIcon className={"w-4 h-4 mr-2"} />}
                        text={t("Add to cart")}
                        type={"submit"}
                        onClick={confirmTicketCountHandler}
                        onMouseEnter={() => setCardSummaryActive(true)}
                    />
                </div>
            </div>
            <CardBackgroundStripes />
        </div>
    );
};

export default ShopCardItem;