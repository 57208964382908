import React from "react";

interface ShopCardItemRoundButtonProps {
    onClick: any;
    symbol: React.ReactNode;
}

export const ShopCardItemRoundButton = (props: ShopCardItemRoundButtonProps) => {
    return (
        <button
            className="rounded-full py-2 px-5 w-14 h-14 flex flex-row justify-center items-center no-underline focus:no-underline active:no-underline hover:no-underline border border-solid border-gray-600 hover:bg-gray-600 bg-transparent select-none text-5xl leading-[0.5rem]"
            type="button"
            onClick={props.onClick}
        >
            <span>{props.symbol}</span>
        </button>
    );
};
