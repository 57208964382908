import ReferralAction from "store/referrals/ReferralAction";
import {Referral} from "types/app";
import update from "immutability-helper";

export interface ReferralReducerPushReferralAction {
    payload: Referral;
    type: typeof ReferralAction.PUSH_REFERRAL;
}

export interface ReferralReducerDeleteReferralAction {
    payload: Referral;
    type: typeof ReferralAction.DELETE_REFERRAL;
}

export interface ReferralReducerPushReferralsAction {
    payload: Referral[];
    type: typeof ReferralAction.PUSH_REFERRALS;
}

export interface ReferralReducerSetReferralsAction {
    payload: Referral[];
    type: typeof ReferralAction.SET_REFERRALS;
}

export interface ReferralReducerClearReferralsAction {
    payload: [];
    type: typeof ReferralAction.CLEAR_REFERRALS;
}

export interface ReferralsReducerState {
    referrals: Referral[];
    appliedReferrals: Referral[];
}

type ReferralsReducerAction =
    | ReferralReducerDeleteReferralAction
    | ReferralReducerPushReferralAction
    | ReferralReducerPushReferralsAction
    | ReferralReducerSetReferralsAction
    | ReferralReducerClearReferralsAction;

class ReferralReducer {
    initialState: ReferralsReducerState = {
        referrals: [],
        appliedReferrals: [],
    };

    reducer = (state: ReferralsReducerState = this.initialState, action: ReferralsReducerAction): ReferralsReducerState => {
        switch (action.type) {
            case ReferralAction.PUSH_REFERRAL:
                const referralIndex = state.referrals.findIndex(referral => referral.id === action.payload.id);

                if (referralIndex !== -1) {
                    return update(state, {
                        referrals: {
                            [referralIndex]: {
                                $set: action.payload,
                            },
                        },
                    });
                }
                return update(state, {
                    referrals: {
                        $push: [action.payload],
                    },
                });

            case ReferralAction.DELETE_REFERRAL:
                const removeReferralIndex = state.referrals.findIndex(referral => referral.guid === action.payload.guid);

                if (removeReferralIndex === -1) {
                    return state;
                }

                return update(state, {
                    referrals: {
                        $splice: [[removeReferralIndex, 1]],
                    },
                });

            case ReferralAction.PUSH_REFERRALS:
                // const referrals = [...new Set([...state.referrals, ...action.payload])]
                // const referrals = state.referrals.filter(referral => !action.payload.includes(referral));
                const idFilter = action.payload.map(item => item.id);
                const referralsFilter = state.referrals.filter(referral => !idFilter.includes(referral.id));
                const referrals = [...new Set([...referralsFilter, ...action.payload])];
                 return update(state, {
                    referrals: {
                        $set: referrals,
                    },
                });

            case ReferralAction.SET_REFERRALS:
                return update(state, {
                    referrals: {
                        $set: action.payload,
                    },
                });

            case ReferralAction.CLEAR_REFERRALS:
                return update(state, {
                    referrals: {
                        $set: [],
                    },
                });

            default:
                return state;
        }
    };
}

export default ReferralReducer;
