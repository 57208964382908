import React from "react";
import NavBarItemContent from "./NavBarItemContent";

const NavBarItemNoLink = props => {
    const {img, text, onClick, textVisible} = props;
    return (
        <button className="NavBarItem w-full h-auto" onClick={onClick}>
            <div className="relative flex h-full content-start justify-start items-center text-gray-500">
                <NavBarItemContent img={img} text={text} textVisible={textVisible}/>
            </div>
        </button>
    );
};

export default NavBarItemNoLink;
