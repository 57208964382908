import React from "react";
import CardBackground from "./CardBackground";

const CardContainer = props => {
    return (
        <div className="relative w-full">
            <div className="relative z-3 flex w-full h-full p-3 sm:p-4 flex-col justify-start items-center">{props.children}</div>
            <CardBackground background={"bg-white"} rounded={"rounded-xl"} shadow={"shadow-md"} />
        </div>
    );
};

export default CardContainer;
