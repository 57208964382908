import React from "react";
import {Profile} from "types/entities/Profile";

interface CardProfileUsernameProps {
    profile: Profile;
}

const CardProfileUsername = (props: CardProfileUsernameProps) => {
    return (
        <div className="CardProfileUsername font-normal mt-2 2xl:mt-4">
            <div className="text-center font-k flex flex-col justify-center items-center text-center">
                <div className="text-2xl 2xl:text-4xl">
                    {props.profile.username}
                </div>
                <div
                    className="text-sm 2xl:text-base font-i opacity-50">{props.profile.name} {props.profile.last_name}</div>
            </div>
        </div>
    );
};

export default CardProfileUsername;
