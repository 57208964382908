import React from "react";
import {useTypedSelector} from "helpers/reducers";
import clsx from "clsx";
import {ForTemplate} from "types/entities/RaceResultData";

interface RaceResultDriversProps {
    res: ForTemplate;
}
const RaceResultDrivers = (props: RaceResultDriversProps) => {
    const session = useTypedSelector(state => state.sessionState.session);
    const loggedUserId = session?.uid;
    const detailStatsLength = props.res.detailStats.length;

    let index = 1;

    return (
        <div
            className={`RaceResultDrivers relative grid mt-0 mb-2 pl-4 pr-4 justify-start items-start grid-rows-1 grid-results-${
                detailStatsLength + 1
            } border-b border-b-gray-300 border-t border-t-red-300 `}
        >
            <div className="relative z-3 p-2 text-left max-w-[4rem]">
                <div className="font-sans-serif-g text-sm leading-5 font-normal">Lap</div>
            </div>

            {props.res.result && props.res.result.raceResultDrivers && props.res.result.raceResultDrivers.map((raceResultDriver, detailId) => {
                const isLoggedUser = raceResultDriver.driver_id === loggedUserId;
                const raceDetail = props.res.detailStats.filter(detailStat => detailStat.driver_id === raceResultDriver.driver_id);
                return (
                    <div key={detailId} className="relative z-3 p-2 text-left min-w-[120px]">
                        <div
                            className={clsx("font-sans-serif-g text-xs leading-5 font-normal", isLoggedUser && "text-red-f0")}>
                            <div className={"text-sm"}>
                                {index++}. {raceDetail[0].driver_name}
                            </div>
                            <div>Kart {raceDetail[0].lastKart}</div>
                            <div>⌀: {(raceDetail[0].averageLapTime / 100).toFixed(2)}s</div>
                            <div>{raceDetail[0].totalLapsCount} laps</div>
                        </div>
                    </div>
                );
            })}
        </div>);
};

export default  RaceResultDrivers;