import React from "react";
import {withRouter} from "react-router";
import "pages/Front/PageNotFound/PageNotFound.scss";
import Icon from "components/Icon";

const PageNotFound = () => {
    return (
        <div className="PageNotFound">
            <div className="container">
                <div className="PageNotFound-content">
                    <h1>This page does not exist :(</h1>
                    <a className="blue-button" href="/">
                        <Icon type="ArrowBackIcon" /> Back to main page
                    </a>
                </div>
                <footer></footer>
            </div>
        </div>
    );
};

export default withRouter(PageNotFound);
