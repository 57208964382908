import React from "react";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/Loader";
import CardBackgroundStripes from "./CardBackgroundStripes";
import CardTag from "./CardTag";
import CardProfileImage from "./CardProfileImage";
import CardProfileUsername from "./CardProfileUsername";
import CardProfileSpeedLevel from "./CardProfileSpeedLevel";
import CardProfileToolbar from "./CardProfileToolbar";
import {Link} from "react-router-dom";

const WidgetProfile = () => {
    const profile = useTypedSelector(state => state.sessionState.profile);

    if (!profile) {
        return <Loader />;
    }

    return (
        <div className="WidgetProfile relative w-full min-w-2xs flex-grow">
            <div className="WidgetProfileCard relative flex flex-col h-full items-center rounded-4xl justify-start">
                <div className="z-3 w-full h-full flex flex-col px-4 sm:px-4 pt-4 pb-8 justify-start cursor-pointer items-center">
                    <CardTag label={"Profile"} />
                    <Link to={"/profile"}>
                        <CardProfileImage profile={profile} />
                    </Link>
                    <CardProfileUsername profile={profile} />
                    <CardProfileSpeedLevel profile={profile} />
                    <CardProfileToolbar />
                </div>
                <CardBackgroundStripes />
            </div>
        </div>
    );
};

export default WidgetProfile;
