import React from "react";
import clsx from "clsx";

interface ShopItemTextProps {
    text: string;
    className?: string;
}

export const ShopItemText = (props: ShopItemTextProps) => {
    return (
        <span className={clsx(props.className, "font-k font-normal text-2xl sm:text-3xl 2xl:text-4xl active:no-underline focus:no-underline hover:no-underline uppercase")}>
            {props.text}
        </span>
    );
};
