import React from "react";
import clsx from "clsx";

interface TitleTextProps {
    text: string;
    className?: string;
}

export const TitleText = (props: TitleTextProps) => {
    return (
        <div className={clsx(props.className, "font-k font-medium text-2xl lg:text-2xl xl:text-4xl 2xl:text-5xl leading-[1] transform-gpu uppercase skew-x-[-10deg] my-0")}>
            {props.text}
        </div>
    );
};
