import React, {useEffect} from "react";
import Dropdown from "components/Dropdown";
import {useTypedSelector} from "helpers/reducers";
import {useDispatch} from "useDispatch";
import HallAction from "store/hall/HallAction";
import HallLogo from "pages/Front/components/HallLogo";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {DEFAULT_HALL} from "helpers/config";
import {Hall} from "types/entities/Hall";
import Loader from "components/Loader";
import TrackImage from "./TrackImage";

const SwitchHallDropdown = () => {
    const halls = useTypedSelector(state => state.hallState.hallsList);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const defaultHall = halls && halls.find((hall: Hall) => hall.hall_code === DEFAULT_HALL);
    const defaultHallCategory = defaultHall && defaultHall.category_id;
    const changeHall = id => {
        dispatch(HallAction.updateHomeHallId(id)).then(() => {
            toast.success(t("Hall has been switched"));
        });
    };

    useEffect(() => {
        if (!halls) {
            dispatch(HallAction.fetchHalls());
        }
    }, []);

    if (!halls) {
        return <Loader />;
    }

    return (
        <Dropdown title={<div className={"w-6 h-6 text-dark-900 my-2"}><TrackImage /></div>}>
            {halls &&
                halls
                    .filter((hall: Hall) => hall.category_id === defaultHallCategory)
                    .map(hall => (
                        <div
                            key={hall.id}
                            className="SwitchHallDropdownItem z-5 flex p-4 justify-center items-center bg-white hover:bg-gray-200 border-b border-solid border-gray-200"
                        >
                            <button className="c-nav-location w-inline-block" type="button" onClick={() => changeHall(hall.id)}>
                                <HallLogo hall={hall} />
                            </button>
                        </div>
                    ))}
        </Dropdown>
    );
};

export default SwitchHallDropdown;
