import React from "react";

const BarLoader = props => {
    return (
        <div className={"BarLoader"} style={{zIndex: 120}}>
            <div className="BarLoaderLine" />
        </div>
    );
};

export default BarLoader;
